// Checkout
// ---
.e-checkout-stage {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  margin-left: 0;
  padding-top: 1.2rem;
  position: relative;
  text-align: center;
}

.e-checkout-stage > li:not(:first-child) {
  align-items: center;
  display: flex;
  position: relative;
}

// Line
.e-checkout-stage > li:not(:first-child)::before {
  background-color: $secondary-color;
  content: '';
  height: .125em;
  width: 16em;

  // 1023px and below
  @include breakpoint(medium down) {
    width: 10em;
  }

  // 767px and below
  @include breakpoint(767px down) {
    width: 8em;
  }

  // 639px and below
  @include breakpoint(small down) {
    width: 5em;
  }

  // 480 and below
  @include breakpoint(480px down) {
    width: 2em;
  }
}

// Circle
.e-checkout-stage--step {
  background-color: $secondary-color;
  border-radius: 50%;
  display: flex;
  height: 3.215em;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 3.215em;
}

.e-checkout-stage--label,
.e-checkout-stage--step {
  @include font-size(14);
  font-family: $diaBold;
}

// Label
.e-checkout-stage--label {
  color: $medium-gray;
  letter-spacing: 1.7px;
  margin-top: -1.5em;

  // 639px and below
  @include breakpoint(small down) {
    @include font-size(12);
  }
}

// Number inside circle
.e-checkout-stage--step > span:last-child {
  color: $white;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

// Item
.e-checkout-stage > li {
  align-items: center;
  display: flex;
  position: relative;
  &[data-step-nav] {
    cursor: pointer;
  }
}

// active
.e-checkout-stage > .is-active > .e-checkout-stage--step,
.e-checkout-stage > .is-active::before {
  background-color: $primary-color !important;
}

.e-checkout-stage > .is-active ~ .e-checkout-stage--item::before {
  background-color: $alto-color;
}

.e-checkout-stage > .is-active ~ .e-checkout-stage--item > .e-checkout-stage--step {
  background-color: $medium-gray;
}

.flash {
  color: red;
  font-weight: bold;
}


.discount {
  &-code:disabled {
    background-color: #dddddd;
  }
  &-submit:disabled, &-submit:disabled:hover {
    background-color: #bbbbbb;
  }
  &-remove:disabled, &-remove:disabled:hover {
    background-color: #bbbbbb;
  }
}

#order-summary-container {
  position: relative;
}