.caret {
  display: inline-block;

  &--up {
    @include caret-arrow($medium-gray, up);

    &.is-primary {
      @include caret-color($primary-color, up);
    }

    &.is-secondary {
      @include caret-color($secondary-color, up);
    }

    &.is-white {
      @include caret-color($white, up);
    }

    &.is-gray {
      @include caret-color($medium-gray, up);
    }
  }

  &--right {
    @include caret-arrow($medium-gray, right);

    &.is-primary {
      @include caret-color($primary-color, right);
    }

    &.is-secondary {
      @include caret-color($secondary-color, right);
    }

    &.is-white {
      @include caret-color($white, right);
    }

    &.is-gray {
      @include caret-color($medium-gray, up);
    }
  }

  &--down {
    @include caret-arrow($medium-gray, down);

    &.is-primary {
      @include caret-color($primary-color, down);
    }

    &.is-secondary {
      @include caret-color($secondary-color, down);
    }

    &.is-white {
      @include caret-color($white, down);
    }

    &.is-gray {
      @include caret-color($medium-gray, up);
    }
  }

  &--left {
    @include caret-arrow($medium-gray, left);

    &.is-primary {
      @include caret-color($primary-color, left);
    }

    &.is-secondary {
      @include caret-color($secondary-color, left);
    }

    &.is-white {
      @include caret-color($white, left);
    }

    &.is-gray {
      @include caret-color($medium-gray, up);
    }
  }

  // Large caret `.is-large`
  &.is-large {
    transform: scale(1.5) translateY(-2px);
  }
}
