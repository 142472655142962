
// 1024px and up
@include breakpoint(large up) {
  .animate {
    @include visibility(hide);
    backface-visibility: hidden;
    position: relative;
    transition-property: transform, opacity, visibility;

    &[class^='ease-'],
    &[class*=' ease-'],
    &[class^='fade-'],
    &[class*=' fade-'] {
      transition-timing-function: ease;
    }

    &[class^='ease-in-'],
    &[class*=' ease-in-'] {
      transition-timing-function: ease-in;
    }

    &[class^='ease-out-'],
    &[class*=' ease-out-'] {
      transition-timing-function: ease-out;
    }

    &[class^='ease-in-out-'],
    &[class*=' ease-in-out-'] {
      transition-timing-function: ease-in-out;
    }

    &.show {
      @include visibility(show);

      &.duration-1 {
        transition-duration: 100ms;
      }

      &.duration-2 {
        transition-duration: 150ms;
      }

      &.duration-3 {
        transition-duration: 250ms;
      }

      &.duration-4 {
        transition-duration: 300ms;
      }

      &.duration-5 {
        transition-duration: 350ms;
      }

      &.duration-6 {
        transition-duration: 400ms;
      }

      &.duration-7 {
        transition-duration: 450ms;
      }

      &.duration-8 {
        transition-duration: 500ms;
      }

      &.duration-9 {
        transition-duration: 550ms;
      }

      &.duration-10 {
        transition-duration: 600ms;
      }

      // Delay
      // ---
      &.delay-1 {
        transition-delay: 100ms;
      }

      &.delay-2 {
        transition-delay: 200ms;
      }

      &.delay-3 {
        transition-delay: 300ms;
      }

      &.delay-4 {
        transition-delay: 400ms;
      }

      &.delay-5 {
        transition-delay: 500ms;
      }

      &.delay-6 {
        transition-delay: 600ms;
      }

      &.delay-7 {
        transition-delay: 700ms;
      }

      &.delay-8 {
        transition-delay: 800ms;
      }

      &.delay-9 {
        transition-delay: 900ms;
      }

      &.delay-10 {
        transition-delay: 1000ms;
      }

      &.delay-11 {
        transition-delay: 1100ms;
      }

      &.delay-12 {
        transition-delay: 1200ms;
      }

      &.delay-13 {
        transition-delay: 1300ms;
      }

      &.delay-14 {
        transition-delay: 1400ms;
      }

      &.delay-15 {
        transition-delay: 1500ms;
      }
    }

    &[class*='-up'] {
      transform: translateY(80px);
    }

    &[class*='-up-full'] {
      transform: translateY(100%);
    }

    &[class*='-left'] {
      transform: translateX(80px);
    }

    &[class*='-down'] {
      transform: translateY(-80px);
    }

    &[class*='-down-full'] {
      transform: translateY(-100%);
    }

    &[class*='-right'] {
      transform: translateX(-80px);
    }

    &[class^='ease-'],
    &[class*=' ease-'] {
      &.show {
        transform: none;
      }
    }
  }
}

.bounce-top {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-1-7 14:47:57
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

