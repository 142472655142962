// Hero
// ---
.hero {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 320px;
  position: relative;
  z-index: 3;

  // enable fixed background image
  &.is-fixed {
    // 1025px and up
    @include breakpoint(1025px up) {
      background-attachment: fixed;
    }
  }

  // enable overlay of bero banner `.has-overlay`
  // `has-overlay-[1~3]` value increase the darker
  &.has-overlay-1,
  &.has-overlay-2,
  &.has-overlay-3 {
    position: relative;
  }

  &.has-overlay-1::before {
    @include overlay-transparent($black, .1);
  }

  &.has-overlay-2::before {
    @include overlay-transparent($black, .3);
  }

  &.has-overlay-3::before {
    @include overlay-transparent($black, .6);
  }

  // Enable hero full height
  &.is-full {
    // 768px[width] and up && 640px[height] and up
    @media screen and (min-height: 640px) and (min-width: 768px) {
      height: 100vh;
    }
  }

  // 639px and below
  @include breakpoint(small down) {
    margin-bottom: 25px;
  }
}

// not full height
.hero:not(.is-full) {
  // 768px and up
  @include breakpoint(768px up) {
    height: 754px;
    min-height: 754px;
  }
}

.hero-layout {
  display: block;

  // 768px[width] and up && 640px[height] and up
  @media screen and (min-height: 640px) and (min-width: 768px) {
    display: flex;
  }
}

// hero dedicated container
.hero-container {
  margin: 0 auto;
  max-width: 1250px;
  padding: 100px 0 20px;
  position: relative;
  width: 100%;

  // 768px and up
  @include breakpoint(768px up) {
    padding: 100px 0;
  }
}

.hero-container > .hero-content {
  padding: 50px;
  position: relative;
  width: 100%;
}

// hero white block
.hero-white-block {
  background-color: $white;
  margin-left: auto;
  max-width: 700px;
  padding: 20px;
  width: 100%;

  // 1200px and below
  @include breakpoint(1200px down) {
    max-width: 60%;
  }

  // 767px[width] and down && 639px[height] and down
  @media screen and (min-height: 320px) and (max-width: 767px) {
    max-width: calc(100% - 20px);
  }

  .hero-white-block--head {
    @include font-size(27);
    margin-bottom: .2em;

    // 768px and up
    @include breakpoint(768px up) {
      @include font-size(40);
    }
  }

  // 768px[width] and up && 640px[height] and up
  @media screen and (min-height: 640px) and (min-width: 768px) {
    bottom: 0;
    position: absolute;
    right: 0;
  }

  // 769px[width] and up && 640px[height] and up
  @media screen and (min-height: 640px) and (min-width: 769px) {
    padding: 2.5rem 5rem 2.5rem 2.5rem;
  }
}

.hero-layout--one,
.hero-layout--two,
.hero-layout--three {
  .hero-content > h1,
  .hero-content > h2,
  .hero-content > p {
    color: $white;
  }
}

// Hero Layout 1
// ---
.hero-layout--one {
  align-items: flex-end;
  justify-content: flex-start;

  // 1200px and below
  @include breakpoint(1200px down) {
    align-items: center;
  }
}

.hero-layout--one > .hero-container > .hero-content {
  max-width: 45%;

  // 1200px and below
  @include breakpoint(1200px down) {
    max-width: 60%;
  }

  // 767px and down
  @include breakpoint(767px down) {
    max-width: 100%;
    padding: 0 20px;
  }
}
// End Hero Layout 1

// Hero Layout 2
// ---
.hero-layout--two {
  // 1023px and down
  @include breakpoint(medium down) {
    > .hero-container > .hero-content > h1 {
      text-align: center;
    }
  }
}

// Hero Layout 3
// ---
.hero-layout--three {
  align-items: center;

  > .hero-container > .hero-content {
    text-align: right;
  }
}
