// Newsletter
// ---
.newsletter {
  .input-email,
  button {
    background: none;
    margin-bottom: 0;
  }
}

.newsletter--wrapper {
  background-color: $shipGray-color;
  padding: 5px 25px 30px;

  .newsletter--text {
    color: #fff;
    margin: 14px 0;
    text-transform: uppercase;
    font-family: $diaBold;
    @include font-size(11);
  }
}


// Newsletter Input Group
.newsletter--input-group {
  background-color: $dark-gray;
  padding: 30px 20px 40px;

  // 639px and up
  @include breakpoint(640px up) {
    padding: 30px;
  }
}

.newsletter--input-group > .newsletter--form {
  border-bottom: 2px solid $porcelain-color;
  display: flex;
}

.newsletter--input-group > .newsletter--form > .input-email {
  @include font-size(20);
  border: 0;
  box-shadow: none;
  color: $porcelain-color;
  font-family: $leituraDisplayRoman;
  height: auto;
  padding: 5px;

  &::placeholder {
    color: rgba($porcelain-color, 1);
    transition: color .25s ease;
  }

  &:focus::placeholder {
    color: rgba($porcelain-color, .3);
  }

  // 1024px and up
  @include breakpoint(large up) {
    @include font-size(30);
  }
}

.newsletter--input-group > .newsletter--form > button {
  padding-left: 1em;
}

.newsletter--input-group > .newsletter--form > button:hover {
  > .caret {
    @include caret-color(darken($white, 30%), right);
  }
}
