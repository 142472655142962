.card--related {
  backface-visibility: hidden;
  display: inline-block;
  transition: transform .5s ease;
}

.card--related:hover {
  transform: translateY(-.6em);
}

// Card for product listing
.card--product-list > figure {
  align-items: center;
  background-color: $white;
  border: 1px solid #e1e1e1;
  display: flex;
  justify-content: center;
  margin-bottom: .5rem;
  min-height: 202px;
  padding: .5rem;
  text-align: center;
  transition: border-color .25s ease;

  height: 210px;

  &:hover {
    border: 1px solid darken(#e1e1e1, 15%);
  }

  > a {
    display: block;
  }

  > a > img,
  > img {
    max-height: 202px;
  }
}
