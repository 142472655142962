
































.product-finish-tab {
  cursor: pointer;
  display: inline-block;
  padding: 15px;
  background-color: white;
  border-bottom: 1px solid #c9c9c9;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  margin-bottom: -2px;
  &.active {
    background-color: #f4f5f6;
    border-bottom: 2px solid #f4f5f6;
    border-right: 1px solid #c9c9c9;
    border-top: 1px solid #c9c9c9;
    border-left: 1px solid #c9c9c9;
    margin-bottom: -2px;
  }
}
