// Default styles
.tabs {
  list-style: none;
  margin: 0;
}

.tabs > li {
  display: inline-block;
}

.tabs-content > .tabs-panel:not(.is-active) {
  display: none;
}
