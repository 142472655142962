// Helpers
// ---

// Borders
// ---
// .border-0 {
//   border: 0;
// }
//
// .border-top-1 {
//   @include border(top, 1px, solid, $primary-color);
// }
//
// .border-top-2 {
//   @include border(top, 2px, solid, $primary-color);
// }

// Box Shadow 1
// ---
.has-shadow-1 {
  box-shadow: 0 20px 30px 0 rgba($black, .2);
}

// Visually Hidden
.visually-hidden {
  @include visibility(hide);
}

// Background colors
// ---
.background-color {

  // Primary
  &--primary {
    background-color: $primary-color;
  }

  // Secondary
  &--secondary {
    background-color: $secondary-color;
  }

  // Secondary Dark
  &--secondary-dark {
    background-color: $secondaryDark-color;
  }

  // Secondary Darker
  &--secondary-darker {
    background-color: $secondaryDarker-color;
  }

  // Dark Gary
  &--dark {
    background-color: $medium-gray;
  }

  // Light gray
  &--light {
    background-color: $porcelain-color;
  }

  // White
  &--white {
    background-color: $white;
  }
}

// HR
hr {
  // full width
  &.full {
    max-width: 100%;
  }
}
