.input-thin-border,
.input-thin-border:hover,
.input-thin-border:focus {
  border-width: 1px;
}

// This is the input layout that ecommerce inputs has
.input-layout-1 {
  color: $secondary-color !important;
  font-family: $leituraDisplayRoman;
}
