// Circle badge
// ---
.badge-circle {
  align-items: center;
  backface-visibility: hidden;
  background-color: $porcelain-color;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  height: 45px;
  justify-content: center;
  transition-duration: .25s;
  transition-property: background-color, border-color;
  transition-timing-function: ease;
  width: 45px;
}
