// CTA for `Find Parts` & Customer Service
// ---

.cta {
  background-color: $dark-gray;
  padding: 1.2rem;

  // CTA for Find Parts
  &--ui-one {
    background-color: $secondary-color;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto 50%;
    padding: 6.25rem 3.125rem;

    &.cta--background-reverse {
      background-position: left bottom;
      background-size: auto 40%;
    }

    // cta icon
    .cta--icon > svg {
      // @include font-size(50);
      color: $white;

      // 640px and up
      // @include breakpoint(640px up) {
      //   @include font-size(70);
      // }
    }

    // 1200px and up
    @include breakpoint(xlarge up) {
      padding: 6.25rem;
    }

    // 1024 and up
    @include breakpoint(large up) {
      background-size: contain;
    }

    // 639px and down
    @include breakpoint(small down) {
      background-size: auto 35%;
      padding: 5rem 1.7rem;
    }
  }

  // CTA for Customer Service
  &--ui-two {
    background-color: $primary-color;
    padding: 3.4rem 3rem;

    .cta--title {
      @include font-size(35);

      // 1200px and up
      @include breakpoint(xlarge up) {
        @include font-size(45);
      }
    }

    // 639px and down
    @include breakpoint(small down) {
      padding: 2rem 1.5rem;
    }
  }

  // CTA for Find a Dealer
  &--ui-three  {
    .cta-thumb {
      max-height: 135px;
    }

    .cta-heading {
      padding: 1rem 0;

      // 640px and up
      @include breakpoint(640px up) {
        margin: 0;
      }
    }

    // 1024px and up
    @include breakpoint(large up) {
      padding: 0 2rem;
    }
  }

  // CTA for Blog Listing
  &--ui-four {
    background-color: $secondary-color;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: auto 90%;
    padding: 6.25rem 3.125rem;

    // cta icon
    .cta--icon {
      @include font-size(50);
      color: $white; // 640px and up
      @include breakpoint(640px up) {
        @include font-size(70);
      }
    }

    // 1200px and up
    @include breakpoint(xlarge up) {
      padding: 2rem;
    }

    // 639px and down
    @include breakpoint(small down) {
      background-size: auto 35%;
      padding: 5rem 1.7rem;
    }
  }

  &--ui-slim {
    padding: 0;
    .cta--image > img {
      max-height: 200px;
    }
    .cta--link-wrapper {
      text-align: right;
    }

    @include breakpoint(medium up) {
      padding: 0 3rem 0 0;
    }

    //// 639px and down
    @include breakpoint(medium down) {
      .cta--image {
        display: none;
      }
      .cta--title-extra-small {
        padding: 1rem;
      }
    }
  }
}

.cta--title {
  @include font-size(30);
  color: $white;

  // 640px and up
  @include breakpoint(640px up) {
    @include font-size(50);
  }
}

.cta--title-small {
  @include font-size(30);
  color: $white;

  // 640px and up
  @include breakpoint(640px up) {
    @include font-size(30);
  }
}
.cta--title-extra-small {
  @include font-size(25);
  color: $white;

  // 640px and up
  @include breakpoint(640px up) {
    @include font-size(30);
  }
}

.cta--description {
  @include font-size(14);
  color: $white;
  font-family: $diaBold;
  line-height: 1.7;
  text-transform: uppercase;
}
