.breadcrumbs > li {
  font-family: $diaRegular;
  &.no-slash {
    margin-right: 10px;
    &::after {
      content: none;
    }
  }
}

.breadcrumbs > li,
.breadcrumbs > li > a {
  letter-spacing: 1px;
}
