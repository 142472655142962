// Background patterns
// ---
.background-pattern {

  // Pattern 1 `background-pattern--one`
  &--one {
    background-image: url('../../images/svg-pattern/pattern-1.svg');
    background-repeat: repeat;
  }

  // Pattern 2 `background-pattern--two`
  &--two {
    background-image: url('../../images/svg-pattern/pattern-2.svg');
    background-repeat: repeat;
  }

  // Pattern 3 `background-pattern--three`
  &--three {
    background-image: url('../../images/svg-pattern/pattern-3.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
