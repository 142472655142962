// Rage Listing
// ---
.rl-block {
  background-color: $porcelain-color;
  height: 100%;
  margin-bottom: 2rem;
  padding: 1.5rem;
  width: 100%;
  a.link-caret {
    text-transform: uppercase;
  }

  // 640px and up
  @include breakpoint(640px up) {
    padding: 2.3rem;
  }
}

.rl-block > .rl-block--text {
  padding-right: 2rem;
}

.rl-block > .rl-block--image {
  text-align: center;
}

// Even row
.range-listing > .range-listing--row:nth-child(even) {
  // 1024px and up
  @include breakpoint(large up) {
    // text block set `order: 2`
    .rl-block > .rl-block--text {
      order: 2;
      padding-right: 0;
    }

    // text block set `order: 1` and add padding
    .rl-block > .rl-block--image {
      order: 1;
      padding-right: 2rem;
    }
  }
}


.rl-images img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  vertical-align: middle;
  max-width: 95%;
  height: auto;
  max-height: 95%;
}

.rl-images figure {
  margin: 0;
  width: 300px;
  height: 300px;
  position: relative;
  display: block;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding: 15px;
}
