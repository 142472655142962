// About
// ---

// About us banner brooklyn-made logo
.about-banner-bml {
  margin: 2em 0;
  text-align: center;

  // 1024px and up
  @include breakpoint(large up) {
    margin: 5em 0 0 10em;
    text-align: left;
  }
}

// Accolates Listing
.accolades-listing {
  padding: 6rem 0;

  // 639px and down
  @include breakpoint(small down) {
    padding: 2rem 0;
  }
}

.accolades-block {
  display: block;
  max-width: 850px;
  width: 100%;

  // 480px and up
  @include breakpoint(480px up) {
    display: flex;
  }
}

.accolades-block > .accolades-block--img {
  max-width: 500px;
  width: 100%;

  > figure > img {
    max-height: calc(100% - 2rem);
    max-width: calc(100% - 2rem);
  }

  // 1023px and down
  @include breakpoint(medium down) {
    max-width: 50%;
  }

  // 479px and down
  @include breakpoint(480px down) {
    max-width: 100%;
  }
}

.accolades-block > .accolades-block--text {
  flex: 1;
  margin: 1rem 0;

  // 480px and up
  @include breakpoint(480px up) {
    padding: 0 2rem;
  }
}

.accolades-block--figure {
  align-items: center;
  background-color: $white;
  border: 1px solid #cecece;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1rem;
}

.accolades-listing > .accolades-listing--row:nth-child(even) {
  .accolades-block {
    margin-left: auto;
  }

  .accolades-block > .accolades-block--img {
    order: 2;
  }
}

// History Slider
.history {
  .slick-slide {
    img {
      height: 50vh;
    }
  }
}

// Slick Year Dots
.watermark-history-dots {
  margin: 5rem 0;
}

.watermark-history-dots > .slick-dots {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  position: relative;
  text-align: center;

  // line
  &::before {
    background-color: $primary-color;
    content: '';
    display: block;
    height: .3rem;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}

.watermark-history-dots > .slick-dots > li {
  display: inline-block;
  margin: 0 5%;
  text-align: center;

  // circle
  > button {
    background-color: $primary-color;
    border-radius: 50%;
    display: block;
    height: 2.13rem;
    position: relative;
    width: 2.13rem;
    z-index: 1;

    // 639px and below
    @include breakpoint(small down) {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  > button::before {
    backface-visibility: hidden;
    background-color: $white;
    border: .3rem solid $primary-color;
    border-radius: 50%;
    content: '';
    display: block;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition-duration: .7s;
    transition-property: transform;
    transition-timing-function: ease;
    width: 100%;
  }

  // circle text
  > button > span {
    @include font-size(24);
    color: $medium-gray;
    font-family: $diaBold;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    // 639px and below
    @include breakpoint(small down) {
      @include font-size(18);
    }
  }

  // 639px and below
  @include breakpoint(small down) {
    margin: 0 4%;
  }
}

.watermark-history-dots > .slick-dots > li:nth-child(odd) {
  // circle text
  > button > span {
    margin-top: -2rem;
  }
}

.watermark-history-dots > .slick-dots > li:nth-child(even) {
  // circle text
  > button > span {
    margin-top: 2rem;
  }
}

.watermark-history-dots > .slick-dots > .slick-active {
  > button::before {
    transform: translate(-50%, -50%) scale(1);
  }
}

// Slider figure
.watermark-history--figure {
  display: inline-block;
  margin-bottom: .5rem;
  overflow: hidden;
}

// figure caption
.watermark-history--figcaption {
  @include font-size(14);
  margin: 0 auto;
  max-width: 700px;
  padding: 0 1rem;
}

// Slides
.watermark-history--slide {
  backface-visibility: hidden;
  text-align: center;

  > .watermark-history--figure > img {
    backface-visibility: hidden;
    margin: 0 auto;
    max-height: 510px;
    transform: scale(1.2);
    transition-duration: 1s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(.3, 0, .3, 1);
  }

  // active
  &.slick-active {
    > .watermark-history--figure > img {
      transform: scale(1);
      transition-duration: 3s;
    }
  }
}
