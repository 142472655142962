/* purgecss start ignore */
.mm-spn.mm-spn--dark {
    background-color: #38373b;
}

.mm-ocd--open {
    background: rgba(0, 0, 0, .50);
}

.mm-spn li:before,
.mm-spn.mm-spn--navbar:after {
    opacity: 100;
}

.mm-spn a {
    font-family: 'Dia Bold';
    text-transform: uppercase;
}

.mm-spn.mm-spn--navbar:after {
    content: '';
    background-image: url('../../../images/watermark-logo-white.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 180px;
}

@media (-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) { 
    .mm-spn.mm-spn--navbar:after {
        background:url('../../../images/watermark-logo-white@2x.png') no-repeat top left;
        background-size: 180px;
        background-position: center bottom;
    }
}

.mm-spn li:after,
.mm-spn a:not(:last-child):after ,
.mm-spn.mm-spn--navbar ul:before{
    display: none;
}

/* purgecss end ignore */