// Header Section
// ---
.site-header {
  backface-visibility: hidden;
  left: 0;
  position: fixed;
  top: 0;
  transition-duration: .25s;
  transition-property: transform, background;
  transition-timing-function: ease-out;
  width: 100%;
  will-change: transform, background;
  z-index: 51;
}

.sh-grid {
  @include xy-grid;
  padding: 1.875rem .8rem;
  position: relative;

  // 1280px and up
  @include breakpoint(1280px up) {
    padding: 1.875rem 4rem;
  }

  [class^='sh-'],
  [class*=' sh-'] {
    display: flex;
  }

  .sh-section {
    @include visibility(show);
    backface-visibility: hidden;
    transition-duration: .25s;
    transition-property: opacity, visibility;
    transition-timing-function: ease;

    &--ham {
      display: flex;
      align-items: center;
    }

    &--nav1 {
      // 1023px and down
      @include breakpoint(medium down) {
        display: none;
      }
    }

    &--brand {
      @include xy-cell(shrink);

      // 1023px and down
      @include breakpoint(medium down) {
        flex: 1 1 auto;
        justify-content: center;
      }
    }

    &--nav2 {
      text-align: right;

      // 1023px and down
      @include breakpoint(medium down) {
        display: none;
      }
    }

    &--search {
      align-items: center;

      button,
      .search-icon {
        // 1024px and up
        @include breakpoint(large up) {
          margin-left: 2.5rem; // 45px
        }
        .search-icon__close {
            display: none;
        }
      }
    }

    &--nav1,
    &--nav2,
    &--search {
      @include xy-cell(auto);
    }

    &--ham,
    &--search {
      @include xy-cell(shrink);
    }

    &--ham,
    &--nav1,
    &--brand,
    &--nav2,
    &--search {
      @include xy-gutters(0);
    }
  }
}

// Brand
.brand {
  display: inline-block;
  text-align: center;

  > .brand--svg {
    max-width: 180px;
    transition: max-width .25s ease;

    @include breakpoint(1280px up) {
      max-width: 210px;
    }
  }

  // add transition for logo
  > .brand--svg > .watermark-svg-logo,
  > .brand--slogan {
    transition-duration: .25s;
    transition-property: fill, color;
    transition-timing-function: ease;
  }

  // logo small text
  > .brand--slogan {
    color: $medium-gray;
    display: block;
    font-family: $watermarkHandRegular;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: normal;
    margin-top: -2px;
  }
}

// Header behavior
// ---
.headroom--pinned {
  transform: none;
}

.headroom--unpinned {
  transform: translateY(-100%);
  transition-timing-function: ease-in;
}

.headroom--not-top {
  background-color: $white;
}

// Header theme
// ---

// White-color theme
.header-theme--white {
  &.headroom--top {
    .hamburger-menu-icon::before,
    .hamburger-menu-icon::after {
      background-color: $white;
    }
  }

  &.headroom--not-top {
    //background-color: rgba($dark-gray, .5);
  }

  &.headroom--top .sh-section--search > .search-icon,
  &.headroom--top .header-search--button > button,
  &.headroom--top .brand > .brand--slogan {
    color: $white;
  }

  &.headroom--top .header-search--input>input {
    color: $shipGray-color;
  }

  &.headroom--top .header-search--input > input {
    &::placeholder {
      color: $medium-gray;
    }
  }

  // brand logo
  &.headroom--top .brand > .brand--svg > .watermark-svg-logo {
    fill: $white;
  }

  // Hamburger
  .hamburger-menu-icon::before,
  .hamburger-menu-icon::after {
  }
}
