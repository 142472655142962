.count-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    @include font-size(13);
    color: $white;
    display: block;
    font-family: $diaBold;
    line-height: 1.4;
    text-align: center;
    width: 1em;
  }

  span:first-child {
    border-bottom: 1px solid $white;
  }
}
