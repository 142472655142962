// Social
// ---
// Usage:
// Default alignment: vertical

.social {
  list-style: none;
  margin-left: 0;

  // Default
  .social-footer__icon {
    // @include font-size(28);
    display: inline-block;
    transition-duration: .25s;
    transition-property: transform, color;
    transition-timing-function: ease-in-out;
  }

  // Horizontal alignment
  &.is-horizontal {
    li {
      display: inline-block;
      float: none;
      margin: 0 .7rem 1rem;

      @include breakpoint(640px up) {
        margin: 0 1.65rem;
      }
    }

    // Social Label
    .social--label {
      margin: 0;
      position: relative;
      top: -4px;
    }
  }

  // Colored icons
  &.social-colored {
    .twitter {
      color: $twitter;
    }

    .fb {
      color: $facebook;
    }

    .pinterest {
      color: $pinterest;
    }

    .instagram {
      color: $instagram;
    }

    .youtube {
      color: $youtube;
    }

    .envelope {
      @include font-size(18);
      color: $dark-gray;
    }
  }

  // Dark
  &.is-dark {
    .appicon {
      color: $dark-gray;
    }

    .appicon:hover {
      color: lighten($dark-gray, 20%);
    }
  }

  // White
  &.is-light {
    .social-footer__icon {
      color: $white;
    }

    .social-footer__icon:hover {
      color: darken($white, 20%);
    }
  }

  &.is-small {
    .appicon {
      @include font-size(24);
    }
  }
}

.social:not(.text-center) {
  text-align: left;
}
