.product-listing {
  background-color: $porcelain-color;
  display: inline-block;
  padding: 2rem;
  width: 100%;

  // 639px and below
  @include breakpoint(small down) {
    padding: 1.2rem;
  }
}

.product-listing-grid {
  // 639px and below
  @include breakpoint(480px down) {
    @include xy-grid-layout(1, '.product-listing--block');
  }
}
