// Gallery
// ---
.gallery-filter {
  margin-bottom: 1.3rem;
  padding: 0 1rem;
  justify-content: center;
}

.gallery-filter--cell:not(:last-child) {
  // 640px and up
  @include breakpoint(640px up) {
    margin-right: .5rem;
  }
}
.gallery-filter--cell select {
  max-width: 200px;
}

.gallery-filter--cell {
  position: relative;
}

.gallery-filter--cell_label {
  position: absolute;
  left: 50%;
  top: -22px;
  transform: translateX(-50%);
}

.gallery-filter-label {
  display: flex;
  font-family: $diaBold;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.filter-clear-button {
  display: flex;
  font-family: $diaBold;
  > button:hover {
    color: $primaryDark-color;
  }
  @include breakpoint(medium down) {
    width: 100%;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

// Gallery Block
.gallery-block {

  // gallery item
  > .gallery-block--item {
    margin-bottom: 1rem;
    padding: 0 .5rem;
    width: 33.333333333333333%;

    // 1024px and up
    @include breakpoint(large up) {
      margin-bottom: 1.875rem;
      padding: 0 1rem;
    }

    // 1023px and below
    @include breakpoint(medium down) {
      width: 50%;
    }

    // 639px and below
    @include breakpoint(small down) {
      margin-bottom: .625rem;
      padding: 0 .3125rem;
    }

    // 479px and below
    @include breakpoint(480px down) {
      width: 100%;
    }
  }

  // Large
  > .gallery-block--large {
    width: 66.7%;

    // 1023px and below
    @include breakpoint(medium down) {
      width: 100%;
    }
  }

  // Medium
  > .gallery-block--medium {
    width: 40%;

    // 1023px and below
    @include breakpoint(medium down) {
      width: 100%;
    }
  }

   // Small
   > .gallery-block--small {
    width: 25%;

    // 1023px and below
    @include breakpoint(medium down) {
      width: 100%;
    }
  }

  .gallery-block--link {
    display: block;
    overflow: hidden;
    position: relative;

    &::before {
      background-color: rgba($black, .5);
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  .gallery-block--link::before,
  .gallery-block--link > .figcaption {
    // 640px and up
    @include breakpoint(640px up) {
      @include visibility(hide);
      backface-visibility: hidden;
      transition-duration: .25s;
      transition-property: transform, opacity, visibility;
      transition-timing-function: ease;
    }
  }

  .gallery-block--link > .figcaption {
    bottom: .5rem;
    padding: 0 1rem;
    position: absolute;

    // 640px and up
    @include breakpoint(640px up) {
      transform: scale(.94);
      transition-duration: .4s;
    }

    > .figcation--heading,
    > .figcation--copy,
    > .figcation--author {
      color: $white;
    }

    > .figcation--heading {
      font-family: $tiemposHeadlineBold;
    }

    > .figcation--copy {
      font-family: $tiemposHeadlineReg;
      margin-bottom: .6rem;
    }

    > .figcation--author {
      @include font-size(13);
      font-family: $diaRegular;
    }

    // 1280px and up
    @include breakpoint(1280px up) {
      bottom: 1rem;
      padding: 0 2rem;
    }
  }

  .gallery-block--link:hover,
  .gallery-block--link:focus {
    > .figcaption {
      // 640px and up
      @include breakpoint(640px up) {
        transform: scale(1);
      }
    }
    > .figcaption,
    &::before {
      @include visibility(show);
    }
  }
}
