.notices {
  background-color: $secondaryDark-color;
  color: #fff;
  text-align: center;
  font-family: $diaRegular;
  padding: 10px;
  & > div {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
  }
  p {
    margin: 0;
  }
}