// Styleguide
// ---
.page-styleguide {
  margin: 150px 0;
}

.guide-title {
  color: $medium-gray;
  font-size: 38px;
  text-align: center;
}

.color-palette {
  .clr {
    display: inline-block;
    height: 120px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;

    &.clr1 {
      background-color: $primary-color;
    }

    &.clr2 {
      background-color: $secondary-color;
    }

    &.clr3 {
      background-color: $light-gray;
    }

    &.clr4 {
      background-color: $medium-gray;
    }

    &.clr5 {
      background-color: $dark-gray;
    }
  }
}
