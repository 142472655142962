// Search
// ---
.header-search {
  backface-visibility: hidden;
  height: 2.6rem;
  left: 0;
  // max-width: 540px;
  width: 85%;
  min-width: 260px;
  opacity: 0;
  position: absolute;
  transform: translate3d(-.8rem, 0, 0);
  z-index: -1;

  @include breakpoint(medium) {
    width: 92%;
  }

  // 1280px and up
  @include breakpoint(1280px up) {
    width: 85%;
    transform: translate3d(3rem, 0, 0);
  }

  .header-search--input {
    padding-right: 10px;

    // input field
    input {
      background: $porcelain-color;
      border-color: $medium-gray;
      border-style: solid;
      border-width: 0 0 2px;
      border: 0;
      box-shadow: none;
      color: inherit;
      font-family: $diaRegular;
      height: 2.2rem;
      margin: 0;
      outline: none;
      padding: 5px 0 5px 10px;
      // margin-left: 5px;
    }

    input::placeholder {
      color: $medium-gray;
      transition: opacity .3s ease;
    }

    input:focus::placeholder {
      opacity: .5;
    }
  }

  .header-search--button {
    // button icon
    button,
    .icon-search {
      color: inherit;
      cursor: pointer;
      font-size: 25px;
      left: 0;
      margin-right: 10px;
      position: relative;
      top: 0;
      // top: 5px;
    }
  }
}
