// Footer nav
// ---
.footer-nav {
  list-style: none;
  margin-bottom: 2.15rem;
  margin-left: 0;
  text-align: center;
}

.footer-nav > li {
  display: inline-block;
  margin: 0 10px;
}

.footer-nav > li > a {
  @include font-size(12);
  color: $white;
  font-family: $diaBold;
  transition: color .25s ease;

  &:hover {
    color: $primary-color;
  }
}
