.video-container {
  cursor: pointer;
  margin-bottom: 30px;
  position: relative;

  > .video {
    margin-bottom: 0;
  }

  > .video > iframe {
    border: 0;
    height: 100%;
    width: 100%;
  }

  > .video-thumb {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition-duration: .9s;
    transition-property: opacity, visibility;
    transition-timing-function: ease;
    width: 100%;
  }

  > .video-thumb > .video-thumb--icon-control {
    @include font-size(20);
    align-items: center;
    backface-visibility: hidden;
    background: $primary-color;
    border: 5px solid transparent;
    border-radius: 50%;
    color: $white;
    display: flex;
    font-family: $watermarkHandRegular;
    font-weight: bold;
    height: 82px;
    justify-content: center;
    left: 50%;
    letter-spacing: 1px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition-duration: .3s;
    transition-property: opacity, background-color, border-color;
    transition-timing-function: ease;
    width: 82px;
    will-change: opacity, background-color;

    // @media 640 and above
    @media screen and (min-width: 640px) {
      height: 110px;
      width: 110px;
    }
  }

  > .video-thumb > .icon-control--loading,
  > .video-thumb > .icon-control--label {
    transition-duration: .3s;
    transition-property: opacity, visibility;
    transition-timing-function: ease;
  }

  > .video-thumb > figcaption {
    background-color: $white;
    bottom: 0;
    line-height: 1;
    padding: 5px 12px;
    position: absolute;

    // 480px and below
    @include breakpoint(480px down) {
      display: none;
    }
  }

  > .video-thumb > figcaption > span {
    @include font-size(11);
    color: rgba($medium-gray, .5);
    font-family: $diaBold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }

  .icon-control--label > .caret {
    margin-right: 4px;
  }

  .icon-control--loading {
    @include loading-1($white);
    @include visibility(hide);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -45%);
  }

  > .video-thumb--buffering > .video-thumb--icon-control > .icon-control--loading {
    @include visibility(show);
  }


  // Paused
  > .video-thumb--paused {
    // Exempted for ITCSS rule
    background-image: none !important;
  }

  > .video-thumb--paused > figcaption {
    @include visibility(hide);
  }

  // Buffering
  .video-thumb--buffering {

    .icon-control--loading::before {
      animation: collideLeft 1s infinite ease-in-out;
    }

    .icon-control--loading::after {
      animation: collideRight 1s infinite ease-in-out;
    }

    .icon-control--label {
      @include visibility(hide);
    }
  }

  // caption left for video
  &.caption-align--left {
    .video-thumb > figcaption {
      left: 0;
    }
  }

  // caption right for video
  &.caption-align--right {
    .video-thumb > figcaption {
      right: 0;
    }
  }
}

.video-container:hover > .video-thumb > .video-thumb--icon-control {
  border: 5px solid darken($primary-color, 5%);
}
