// Split blocks
// ---

// layout one
.split-block--one {

  // This is the first and last second/last
  .split-block--first {
    // 1024px and up
    @include breakpoint(large up) {
      padding-right: 15px;
    }
  }

  .split-block--second {
    // 1024px and up
    @include breakpoint(large up) {
      padding-left: 15px;
    }
  }

  // Image tag
  .split-block--figure > a > img {
    backface-visibility: hidden;
    transition: transform .4s ease-out;
  }

  // Text block
  .split-block--text {

    // 639px and below
    @include breakpoint(768px up) {
      padding: 0 1em;
    }

    // 1200px and below
    @include breakpoint(xlarge up) {
      padding: 0 2em;
    }
  }

  @include breakpoint(large up) {
    // image wrapper
    .split-block--figure {
      overflow: hidden;

      &:hover > a > img {
        transform: scale(1.1);
      }
    }
  }
}

.split-block--wrap {
  padding: 2rem .625rem;

  @include breakpoint (large up) {
    padding: 2rem;
  }
}

// This has the image look like it's outline the content block;
@include breakpoint(large up) {
  .split-block--one-offset > .split-block--col {
    position: relative;
  }

  .split-block--one-offset > .split-block--col::before {
    background-color: $porcelain-color;
    content: '';
    height: 410px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - .9375rem);
    z-index: -1;
  }

  .split-block--one-offset > .split-block--first::before {
    left: 0;
  }

  .split-block--one-offset > .split-block--second::before {
    right: 0;
  }
}

@include breakpoint(medium down) {
  .split-block--one-offset > .split-block--col {
    background-color: $porcelain-color;
  }
}
