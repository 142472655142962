





























.product-finish-pricing {
  display: block;
  position: absolute;
  color: #1b6f9f;
  font-family: 'Dia Regular', sans-serif;
  font-size: 1.5rem;

  top: 155px;
  left: 50%;
  transform: translateX(-50%);
}
.price-group-name {
  font-weight: bold;
}
.price {
  color: #aaaaaa;
}
