// Button
// ---
.button {

  // Thin
  &.is-thin {
    padding: .7em 1em;

    &.small {
      padding: 1.1em 1em;
    }
  }

  // is hollow
  &.hollow {

    // is hollow border width
    &.border-width-1 {
      border-width: 1px;
    }

    &.border-width-2 {
      border-width: 2px;
    }
  }

  // Small button
  &.small {
    padding: .88em 1.5em;
  }

  // icon
  &.has-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;


    .appicon {
      @include font-size(22);
      margin-left: .6rem;
    }
  }

  // Has caret
  &.has-caret {
    &--left > .caret {
      margin-right: 5px;
    }

    &--right > .caret {
      margin-left: 5px;
    }
  }

  // shadow
  &.has-shadow {
    box-shadow: 10px 10px 20px rgba($black, .2);
  }

  // is round button
  &.is-rounded {
    border-radius: 290486px;
  }

}
