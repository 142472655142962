// Search Results Listing
.result-product-info > li {
  @include font-size(16);
  font-family: $diaRegular;
}

.result-product-info > li > span {
  @include font-size(15);
  font-family: $diaBold;
}

.result-product-thumb {
  align-items: center;
  border: 1px solid $mercury-color;
  display: flex;
  justify-content: center;
  min-height: 190px;
  padding: .5rem;
}

.search-in {
  @include font-size(14);
  font-family: $diaRegular;
  margin-bottom: 1rem;
}

.search-results--listing {
  border-bottom: 2px solid $line-color;
  margin-bottom: 2.5rem;
  padding-bottom: 3rem;
}

.result-thumb {
  // 639px and below
  @include breakpoint(small down) {
    margin-bottom: 2rem;
  }
}

.result-thumb img {
  max-height: 190px;
  max-width: 100%;
}
