// Heading
// ---
.h1 {
  @include font-size(38);

  // 640px and above
  @include breakpoint(640px up) {
    @include font-size(58);
  }

  &.xl {
    // 1024px and above
    @include breakpoint(large up) {
      @include font-size(70);
    }
  }
}

.h2 {
  @include font-size(28);

  // 640px and above
  @include breakpoint(640px up) {
    @include font-size(40);
  }
}

.h3 {
  @include font-size(20);

  // 640px and above
  @include breakpoint(640px up) {
    @include font-size(30);
  }
}

// .h4 {
//   font-size: 28px;
// }
//
// .h5 {
//   font-size: 20px;
// }
//
// .h6 {
//   font-size: 16px;
// }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  a {
    color: inherit;
    transition: color .25s ease;
  }
}
