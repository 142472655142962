// Keyframes
// ---
@keyframes collideRight {
  0% {
    transform: translate3d(0);
  }

  50% {
    transform: translate3d(10px, 0, 0);
  }

  100% {
    transform: translate3d(0);
  }
}

@keyframes collideLeft {
  0% {
    transform: translate3d(0);
  }

  50% {
    transform: translate3d(-10px, 0, 0);
  }

  100% {
    transform: translate3d(0);
  }
}
