// Base tags
// ---

body {
  overflow-x: hidden;
}

button {
  cursor: pointer;
}

strong,
b {
  font-family: $diaBold;
  font-weight: normal;
}

// Used to negate browser's default of outline on focus for tabs
a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

.errors {
  color: red;
}