// Product Detail Page
// ---

// Product Heading Title
.product-top-heading--top {
  @include font-size(22);

  // 640px and up
  @include breakpoint(640px up) {
    @include font-size(28);
  }
}


.product-tab-container {
  .product-tab-content {
    position: relative;
    display: block;
    border: 1px solid #e4e4e4;
    margin: -1px;
    .inner-content-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .inner-content {
        position: relative;
        display: block;
        width: 90%;

        &.centered {
          text-align: center;
        }

        h4 {
          text-transform: uppercase;
        }
      }
    }
  }
  .product-tab-bar {
    position: relative;
    display: block;
    z-index: 9;
    left: -1px;
    bottom: 0;
  }
  .product-tab-link {
    font-family: "Dia Bold", sans-serif;
    color: rgba(88,87,90,.8);
    display: inline-block;
    padding: 15px 20px;
    text-transform: uppercase;
    border: 1px solid #e4e4e4;
    background-color: #ececec;
    cursor: pointer;

    &.active {
      border-top: 1px solid transparent;
      background-color: white;
    }
  }
}

.ar-desktop-only {
  @include breakpoint(1000px down) {
    display: none !important;
  }
}

figure.ar-figure {
  position: relative;
}
.product-ar-icon {
  position: absolute;
  right: 8px;
  bottom: 8px;
  cursor: pointer;
}
.product-qr-code {
  position: relative;
  display: block;
  padding: 10px;
  margin: auto;
  width: fit-content;
  height: fit-content;
  box-shadow: 2px 2px 5px grey;
}

// Product Slider Container
.product-slider-container {
  border: 1px solid $mercury-color;
  min-height: 320px;
  padding: .6rem;
}

// Product Slider Placeholder Figure
.product-slider--figure {
  text-align: center;
  //overflow: hidden;
  position: relative;
}

// Product Slider Placeholder Large image
.product-slider--figure  > img {
  backface-visibility: hidden;
  margin: 0 auto;
  max-height: 350px;
  //transform: scale(.8);
  transition-duration: 1s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(.3, 0, .3, 1);
}

// Product Slider slide
.product-slider--slide {
  // active
  &.slick-active,
  &.slick-current {
    > .product-slider--figure  > img {
      transform: scale(1);
      transition-duration: 1.5s;
    }
  }
}

// Product Slider Nav
.product-slider-nav {
  padding: 0 3.8rem;
}

// Product Slider Figure Nav
.product-slider-nav--figure {
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

// This is overlay of slider thumbs
.product-slider-nav--figure::before {
  @include visibility(hide);
  // background-color: rgba($dark-gray, .4);
  box-shadow: 0 0 0 4px #1b6f9f inset;
  content: '';
  height: 100%;
  position: absolute;
  transition-duration: .3s;
  transition-property: opacity, visibility;
  transition-timing-function: ease;
  width: 100%;
}

// Product Detail Slide Nav
.product-slider-nav--slide {
  margin: 0 .4rem;

  &.slick-current > .product-slider-nav--figure::before {
    @include visibility(show);
  }
}

// Product Info Section
.product-info-section {
  margin-top: 1.5rem;

  // 1200px and up
  @include breakpoint(xlarge up) {
    padding-left: 2.5rem;
  }

  // 640px and up
  @include breakpoint(640px up) {
    margin-top: 3rem;
  }
}

.product-info:not(.product-info--last) {
  margin-bottom: 2.6rem;
}

// Product info title and text margin
.product-info--title,
.product-info--text {
  margin-bottom: .5rem;
}

// Product Button Group
.product-button-group {
  // 639px and down
  @include breakpoint(small down) {
    display: block;
    margin-bottom: 0;

    > .button {
      margin-bottom: .5rem;
      margin-right: 0;
    }
  }
}

.product-button-group > .button:not(:last-child) {
  // 640px and up
  @include breakpoint(640px up) {
    margin-right: .5rem;
  }
}

// Finish Pricing
.finish-pricing {
  column-count: 2;
}

.related-products {
  .slick-list {
    margin: 0 60px;
  }
  .slick-prev,
  .slick-next
  {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  button.slick-next, button.slick-prev {
    z-index: 999999999;
  }
  .slick-prev {
    left: 15px;
    transform: rotate(90deg);
  }
  .slick-next {
    right: 15px;
    transform: rotate(-90deg);
  }
  .slick-prev:before,
  .slick-next:before
  {
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev:before
  {
    content: url('../../images/appicons/dropdown.svg');
  }
  .slick-next:before
  {
    content: url('../../images/appicons/dropdown.svg');
  }
  .slick-slide {
    margin: 15px;
  }
}

.below-product-images {
  display: flex;
  padding-top: 20px;
  justify-content: flex-end;

  > div {
    display: flex;
    align-items: center;
  }
  .patent {
    font-size: 12px;
  }
  .badge {
    padding-left: 10px;
    & > img {
      height: 40px;
    }
  }
}

[data-open-modal] {
  cursor: pointer;
}

.configurator-cta {
  margin-top: 3rem;
  width: 100%;
  position:relative;

  &--text {
    background-color: #0e977d;
    padding: 30px 80px 30px 30px;
    color:white;

    @include breakpoint(small down) {
      padding: 30px 30px 50px;
    }

    h4 {
      color:white;
    }
    p {
      font-family: 'Dia Regular', sans-serif;
      text-transform: uppercase;
      line-height: 1.2;
      font-size: 0.9rem;
      margin: 0;
    }
  }
  &--link {
    position:absolute;
    left: 66.6%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include breakpoint(small down) {
      left: 50%;
      bottom: 70px;
      top: unset;
      width: 80%;
    }

    .caret--right {
      margin-left: 10px;
    }
  }
  &--image {
    background-image: url(../../images/product-detail/configurator-2.0.gif);
    background-size: cover;
    min-height: 120px;
    background-position: left;

    @include breakpoint(small down) {
      background-position: center;
    }
  }
}

section.additional-products {
  margin-top: 67px;

  .additional-products-title {
      color: $medium-gray;
      font-family: $leituraDisplayRoman;
      font-size: 20px;
      line-height: 35px;
  }
  .additional-products-subtitle {
    color: $medium-gray;
    font-family: $leituraDisplayRoman;
    font-size: 13px;
    margin-bottom: 10px;
  }
  .additional-products-slider {
    .slick-track {
      margin-left: 0;
    }
  }
  .slider-card-title {
    color: $primary-color;
    font-family: $diaRegular;
    font-size: 16px;
    line-height: 19px;
  }
  .slider-card-description {
    opacity: 0.58;
    color: #58575A;
    font-family: $diaRegular;
    font-size: 12px;
    letter-spacing: 0.86px;
    line-height: 13px;
  }
  .additional-products-slider-card {
    max-width: 112px;
    height: 99px;
    border: 1px solid #EAEAEB;
    img {
      height: 99px;
      object-fit: contain;
      padding: 10px;
      margin-bottom: 12px;
    }
  }

  .prev-button, .next-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 33px;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    background-color: #E2E4E6;
    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
  .prev-button {
    left: 8px;
    .caret {
      border-color: transparent #58575A transparent transparent;
    }
  }
  .next-button {
    right: 8px;
    .caret {
      border-color: transparent transparent transparent #58575A;
    }
  }
}