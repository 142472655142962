// // Mobile Nav
// ---
.mobile-nav {
  @include visibility(hide);
  backface-visibility: hidden;
  background: $dark-gray;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding-top: 100px;
  position: fixed;
  top: 0;
  transition-duration: .25s;
  transition-property: opacity, visibility;
  transition-timing-function: ease-out;
  width: 100%;
  z-index: 50;

  // 992px and above
  @include breakpoint(992px up) {
    @include visibility(hide);
  }

  .accordion {
    background: none;
    &-item {
      background: none;
    }
    &-title {
      border: none;
      padding: 0;
      font-size: 1rem;
      &::before {
        content: unset;
      }
      &:hover {
        background: none;
      }
      &:focus {
        background: none;
      }
    }
    &-content {
      padding: 0;
      background: none;
      border: none;
    }
  }
}

.mobile-nav--menu {
  list-style: none;
  margin: 0;
  padding: 0 15px;
}

.mobile-nav--submenu {
  display: none;

  > ul {
    margin-left: 15px;
    margin-top: 15px;
  }
}

.mobile-nav--menu,
.mobile-nav--submenu > ul {
  list-style: none;
}

.mobile-nav--title,
.mobile-nav--submenu > ul > li > a {
  font-family: $diaBold;
}

.mobile-nav--title,
.mobile-nav--title:focus,
.mobile-nav--title:hover {
  color: $white;
}

.mobile-nav--submenu > ul > li > a {
  color: darken($white, 35%);
}

.mobile-nav--item,
.mobile-nav--submenu > ul > li {
  line-height: 1;
}

.mobile-nav--item {
  margin-bottom: 1.7rem;
  width: fit-content;
}

.mobile-nav--title {
  position: relative;
  transition: color .25s ease;
  text-transform: uppercase;
}

.mobile-nav--submenu > ul > li:not(:last-child) {
  padding-bottom: 10px;
}

.mobile-nav--submenuparent {

  > .mobile-nav--title::after {
    border-color: $white transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    content: '';
    height: 0;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    transition-duration: .25s;
    transition-property: transform, border-color, visibility;
    transition-timing-function: ease;
    width: 0;
  }

  &.is-active > .mobile-nav--title::after {
    border-color: transparent transparent $primary-color;
    border-width: 0 4px 6px;
  }
}
