










div {
    position: relative;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 50px;

    img {
        position: relative;
        display: block;
        width: 50%;
        margin: 0 auto;
    }
}
