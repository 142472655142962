// This is sidenav for my account
.e-sidenav > li {
  margin-bottom: .8rem;
}

.e-sidenav > li > a {
  color: inherit;
  line-height: normal;

  // active
  &.is-active {
    color: $secondary-color;
  }
}

// column that wrap the sidenav `my account`
.e-my-account--sidewrap {
  border-bottom: 2px solid $alto-color;
  margin-bottom: 2rem;

  @include breakpoint(large up) {
    border-right: 2px solid $alto-color;
    border-width: 0 2px 0 0;
    margin: 0;
    padding-right: 1.25rem;
  }
}

// column that wrap the body `my account`
.e-my-account--body {
  @include breakpoint(1025px up) {
    padding-left: 4.4rem;
  }
}
