





























.venveo-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    &-wrap {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.3);
    }
    &-view {
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 300px;
        min-height: 200px;
        max-width: 80vw;
        max-height: 80vh;
        padding: 20px;
        background: rgb(255,255,255);
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
        .modal-exit {
            //
        }
        .item-list {
            //
        }
    }
    // expansion button
    .circle{
        /*border:1px solid #000;*/
        width:40px;
        height:40px;
        background: #ffffff;
        border-radius:100%;
        border: 2px transparent;
        /*position:relative;*/
        /*margin:10px;*/
        position: absolute;
        display:inline-block;
        &.exit {
            top: 7px;
            right: 7px;
            background: rgba(255,255,255,0.8);
            &:hover {
                background: rgb(200,200,200);
                border-color: rgb(200,200,200);
                &:after {
                    background: white;
                }
                &:before {
                    background: white;
                }
            }
        }
        cursor: pointer;
        z-index: 99999999;
    }
    .circle.plus:before,
    .circle.plus:after,
    .circle.exit:before,
    .circle.exit:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(200,200,200);
        z-index: 999999999;
        cursor: pointer;
    }
    .circle.plus:before,
    .circle.exit:before {
        width: 2px;
        margin: 10px auto;
    }
    .circle.plus:after,
    .circle.exit:after {
        margin: auto 10px;
        height: 2px;
    }

    .circle.exit:before,
    .circle.exit:after {
        display: inline-block;
        transform: rotate(45deg);
    }
}
