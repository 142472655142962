// Selection
// ---
@mixin selection($color, $background) {
  $prefixes: ('-moz-', '-webkit-', '-o-', '-ms-', '');
  @each $prefix in $prefixes {
    &::#{$prefix}selection {
      background: $background;
      color: $color;
    }
  }
}

// String Replace
// ---

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// Font Face
// ---

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg, $display: auto) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_')
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: $display;
  }
}

// Visible
// param: `hide` & `show`
// ---

@mixin visibility($value) {
  @if $value == hide {
    opacity: 0;
    visibility: hidden;
  } @else if $value == show {
    opacity: 1;
    visibility: visible;
  }
}

// Overlay tranparent
// ---
@mixin overlay-transparent($color, $opacity, $zindex: auto) {
  background: rgba($color, $opacity);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $zindex;
}

// Border
// ---
@mixin border($pos, $width, $style, $color) {
  border-#{pos}: $width $style $color;
}

// Font Size
// ---
// This will output `rem` unit font-size and `px` as fallback
@function calculate-rem($size) {
  $remSize: #{$size / 16 * 1};
  @return #{$remSize}rem;
}

@mixin font-size($size) {
  font-size: #{$size}px;
  font-size: calculate-rem($size);
}

// Caret Arrow
// ---
// Default: caret-arrow($medium-gray, right)
@mixin caret-arrow($color: $medium-gray, $direction: 'right') {
  height: 0;
  transition-duration: .25s;
  transition-property: border-color, transform, opacity, visibility;
  transition-timing-function: ease;
  width: 0;

  @if $direction == 'up' {
    border-color: transparent transparent $color;
    border-style: solid;
    border-width: 0 4px 6px;
  }

  @if $direction == 'down' {
    border-color: $color transparent transparent;
    border-style: solid;
    border-width: 6px 4px 0;
  }

  @if $direction == 'right' {
    border-color: transparent transparent transparent $color;
    border-style: solid;
    border-width: 4px 0 4px 6px;
  }

  @if $direction == 'left' {
    border-color: transparent $color transparent transparent;
    border-style: solid;
    border-width: 4px 6px 4px 0;
  }

}

// Caret Color
// ---
// Default: caret-color($medium-gray, right)
@mixin caret-color($color: $medium-gray, $direction: 'right') {

  @if $direction == 'up' {
    border-color: transparent transparent $color;
  }

  @if $direction == 'down' {
    border-color: $color transparent transparent;
  }

  @if $direction == 'right' {
    border-color: transparent transparent transparent $color;
  }

  @if $direction == 'left' {
    border-color: transparent $color transparent transparent;
  }

}

@mixin no-select {
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
