// Ecommerce Listing
// ---
.e-lising-block {
  padding: 1rem;

  @include breakpoint(768px up) {
    padding: 3rem;
  }
}

// card
.e-card-list {
  color: inherit;
  display: block;
  margin-bottom: 1rem;

  @include breakpoint(768px up) {
    margin-bottom: 4rem;
  }
}

.e-card-list > figure {
  background-color: $white;
  margin-bottom: .6rem;
  outline-color: $alto-color;
  outline-offset: -1px;
  outline-style: solid;
  outline-width: 1px;
  text-align: center;
}
