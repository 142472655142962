.side-category {
  list-style: none;
  margin-left: 0;

  ul {
    list-style: none;
    margin-left: 0;
  }
}

// side category title
.side-category--title {
  @include font-size(18);
  color: $medium-gray;
  display: block;
  font-family: $diaBold;
  letter-spacing: 2px;
  padding: .7rem 3rem .7rem 1rem;
  position: relative;
  transition: color .25s ease;

  // 1024px and up
  @include breakpoint(large up) {
    &::before {
      box-shadow: 0 .125rem .75rem 0 rgba($black, .15);
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    // This will tamper the shadow
    &::after {
      background-color: $white;
      content: '';
      height: 100%;
      opacity: 0;
      position: absolute;
      right: -.625rem;
      top: 0;
      width: 1.25rem;
      z-index: 1;
    }
  }
}

// This is side-category item
.side-category--item {
  position: relative;

  // active state
  &.is-active > .side-category--title {
    color: $primary-color;
  }

  // active state shadow
  &.is-active > .side-category--title::before,
  &.is-active > .side-category--title::after {
    opacity: 1;
  }

  // active state remove caret
  &.is-active > .side-category--title > .caret {
    opacity: 0;
  }
}

// Item submenu parent caret
.side-category--title > .caret {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  transition-duration: 0s;
}

// This is submenu body of side category nav
.side-category--submenu {
  background-color: $white;
  border-bottom: 2px solid $primary-color;
  box-shadow: 0 .125rem .75rem 0 rgba($black, .15);
  display: none;
  padding: 1.5rem 1rem .8rem;

  // default column
  .sc-submenu-col--table {
    @include xy-grid-layout(2, '.sc-submenu-col--table-cell');
  }

  // 1024px and up
  @include breakpoint(large up) {
    left: 100%;
    position: absolute;
    top: 0;
    // default width
    width: 786px;
  }

  // 1023px and down
  @include breakpoint(medium down) {
    overflow-x: auto;
    overflow-y: auto;
  }

  // 480px and down
  @include breakpoint(480 down) {
    // default column
    .sc-submenu-col--table {
      @include xy-grid-layout(1, '.sc-submenu-col--table-cell');
    }
  }

  // 768px and up
  @include breakpoint(768px up) {
    // default column
    .sc-submenu-col--table {
      @include xy-grid-layout(3, '.sc-submenu-col--table-cell');
    }
  }

  // active state
  &.js-dropdown-active {
    display: block;
  }

  // 1200px and above
  @include breakpoint(xlarge up) {
    padding: 3rem 3rem 1.8rem;
  }
}

// Widths based on number of columns

// 2 column
.side-category--width2 {
  // 1024 and up
  @include breakpoint(large up) {
    width: 600px;
  }

  // 640px and down
  @include breakpoint(640px up) {


    .sc-submenu-col--table {
      @include xy-grid-layout(2, '.sc-submenu-col--table-cell');
    }
  }
}

// 1 column
.side-category--width1 {
  // 1024 and up
  @include breakpoint(large up) {
    width: 350px;
  }

  // 640px and up
  @include breakpoint(640px up) {
    .sc-submenu-col--table {
      @include xy-grid-layout(1, '.sc-submenu-col--table-cell');
    }
  }
}

// Column cell
.sc-submenu-col--table-cell {
  @include xy-cell;
  margin-bottom: .4rem;
  padding-right: 1rem;
}

.sc-submenu-col--table-cell:not(.has-sub-categories) {
  // 767px and down
  @include breakpoint(767px down) {
    width: 100% !important;
  }
}

// 1024px and up
@include breakpoint(large up) {
  .sc-submenu-col--table > .sc-submenu-col--table-cell:last-child {
    padding-right: 0;
  }
}

// This will hide the items below with the class `break`
.category-sub-list:not(.expanded) > .break {
  ~ dd:not(.category-sub-list--expand) {
    display: none;
  }
}

.category-sub-list--title,
.category-sub-list--item,
.category-sub-list--title > a ,
.category-sub-list--item > a {
  @include font-size(13);
  transition: color .25s ease;
}

// Sublist title
.category-sub-list--title {
  margin-bottom: .4rem;
}

.category-sub-list--title,
.category-sub-list--title > a {
  color: $medium-gray;
  display: block;
  font-family: $diaBold;
  font-weight: normal;
}

.category-sub-list--title > a:hover,
.category-sub-list--title > a:focus {
  color: $secondary-color;
}

// Sublist item
.category-sub-list--item,
.category-sub-list--item > a {
  color: $jumbo-color;
  font-family: $diaRegular;
  margin-bottom: .2rem;
}

.category-sub-list--item > a:hover,
.category-sub-list--item > a:focus {
  color: $primary-color;
}

// Side category sublist
.category-sub-list {
  margin-bottom: .4rem;

  // This will hide the `All` trigger once items been expanded
  &.expanded > .category-sub-list--expand {
    display: none;
  }
}
