// Homepage
// ---

// Prefix `h` indicate homepage because this component UI is currently exist in homepage

// Original Section
.h-ui-one {
  // 640px and up
  @include breakpoint(640px up) {
    padding-top: 45px;
  }

  // Content section
  > .h-ui-one--col-content {
    background-color: $secondaryDark-color;
    background-image: url('../../images/svg-pattern/pattern-2.svg');
    background-repeat: repeat;
    position: relative;
    z-index: 2;

    // 640px and up
    @include breakpoint(640px up) {
      top: -45px;
    }
  }

  .h-ui-one--content {
    position: relative;
  }

  .h-ui-one--header,
  .h-ui-one--text {
    padding: 20px;

    // 1200px and up
    @include breakpoint(xlarge up) {
      padding: 35px 50px 35px 80px;
    }

    // 639px and below
    @include breakpoint(small down) {
      padding: 20px .625rem;
    }
  }

  .h-ui-one--header > .h-ui-one--title,
  .h-ui-one--header > p,
  .h-ui-one--text > * {
    color: $white;
  }

  // Header section
  .h-ui-one--header {
    background-color: $secondaryDarker-color;
  }

  .h-ui-one--header > .h-ui-one--title {
    @include font-size(40);
  }

  .h-ui-one--header > .h-ui-one--title,
  .h-ui-one--header > p {
    margin: 0;
  }
  // End Header

  // Logo
  .h-ui--logo {
    background-color: $white;
    display: inline-block;
    margin-bottom: 10px;
    margin-right: -6em;
    padding: 20px;
    position: relative;
    z-index: 1;

    // 640px and up
    @include breakpoint(640px up) {
      float: right;
    }
  }

  // Image section
  > .h-ui-one--col-image {
    min-height: 100%;
    position: relative;
    z-index: 1;
  }

  > .h-ui-one--col-image > .h-ui-one--image {
    background-color: $porcelain-color;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }

  > .h-ui-one--col-image > .h-ui-one--image > .h-ui-one--figure {
    @include breakpoint(640px up) {
      opacity: 0;
    }
  }
}

// Video & About Section
.video-about {
  margin-top: 5.25rem;

  // 1024px and up
  @include breakpoint(large up) {
    margin-top: 8.4rem;
  }

  // 639px and down
  @include breakpoint(small down) {
    margin-top: 3rem;

    .grid-container {
      padding: 0;
    }
  }

  @include breakpoint(860px down) {
    .video-about--video,
    .video-about--content {
      @include xy-cell(100%);

      br {
        display: none;
      }
    }
  }

  // Video Content Padding
  .video-about--contentpadding {
    padding: 0;

    @include breakpoint(861px up) {
      padding: 0 2rem;
    }
  }
}

// Banner
.home-banner {
  position: relative;
  display: block;
  .hero-container {
    height: 90%;
    min-height: 370px;
    .slider-wrappers {
      position: relative;
    }
  }
}

.banner-slider {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  .slick-slide > div > div {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
  }
}

.banner-slider .banner-image-container {
  display: block;
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}


.slider-dots {
  padding: 15px;
  display: block;
  position: absolute;
  @include breakpoint(861px up) {
    bottom: 0;
    left: 0;
  }
  @include breakpoint(860px down) {
    top: 25%;
    right: 0;
    display: block;
  }
}