// Loading 1
// ---
@mixin loading-1($color: $primary-color) {
  transition: opacity 200ms linear;

  &::before,
  &::after {
    backface-visibility: hidden;
    background: $color;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px;
  }
}
// End loading 1
