.e-tab-block {
  background-color: #eef0f2;
  padding-top: 9rem;
}

// tabs for ecommerce landing
.e-tab-block--tabs {
  .tabs {
    display: flex;
    margin-top: -6rem;
    text-align: center;
  }

  .tabs > .tabs-title {
    align-items: center;
    background-color: $geyser-color;
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 1rem;
    transition: background-color .25s ease;

    // 760px and up
    @include breakpoint(769px) {
      padding: 2rem;
    }
  }

  .tabs > .tabs-title:not(:last-child) {
    margin-right: 1rem;
  }

  .tabs > .tabs-title > a {
    @include font-size(14);
    color: $medium-gray;
    cursor: pointer;
    font-family: $diaBold;
    letter-spacing: 1.6px;
    line-height: 1.2;
    transition: color .25s ease;

    // 760px and up
    @include breakpoint(769px) {
      @include font-size(20);
    }
  }

  // active tab
  .tabs > .is-active {
    background-color: $porcelain-color;
  }
}

// Step Label
.step-label {
  @include breakpoint(20);
  background-color: #eef0f2;
  color: $dark-gray;
  display: inline-block;
  font-family: $diaBold;
  padding: 1.125rem 2.5rem;
  text-align: center;
}
