// Colors
// ---
$transparent-color: transparent;
$shipGray-color: #3e3d41; // RGB: 62, 61, 65
$porcelain-color: #f4f5f6; // RGB: 244, 245, 246
$primaryDark-color: #067963; // RGB: 6, 121, 99
$secondaryDark-color: #185275; // RGB: 24, 82, 117
$secondaryDarker-color: #0d405e; // RGB: 13, 64, 94
$aquaDeep-color: #005042; // RGB: 0, 80, 66
$regent-color: #7c899f; // RGB: 124, 137, 159
$mercury-color: #e4e4e4; // RGB: 228, 228, 228
$jumbo-color: #838287; // RGB: 131, 130, 135
$line-color: #e0e1e3; // RGB: 224, 225, 227
$alto-color: #d8d8d8; // RGB: 216, 216, 216
$geyser-color: #dfe4e8; // RGB: 223, 228, 232

$platinum-color: #c6a168;
$gold-color: #c5b769;
$silver-color: #9a97a1;

// Social Media Brand Colors
// ---
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$instagram: #517fa4;

// Fonts
// ---
$watermarkHandRegular: 'Watermark Hand Regular';
$diaRegular: 'Dia Regular';
$diaBold: 'Dia Bold';
$diaLatoBlack: 'Lato Black';
$leituraDisplayRoman: 'Leitura Display Roman';
$leituraNewsRoman1: 'Leitura News Roman 1';
$waterletHandOutlineReg: 'Waterlet Hand Outline Regular';
$waterletHandOutlineBold: 'Waterlet Hand Outline Bold';
$waterletHandFillReg: 'Waterlet Hand Fill Regular';
$tiemposHeadlineReg: 'Tiempos Headline Regular';
$tiemposHeadlineBold: 'Tiempos Headline Bold';
