// BLog Listing
// ---

.blog-listing {
  .grid-container {
    max-width: 800px;
  }

  .blog-item {
    padding: 30px;

    h3 {
      color: $secondary-color;
    }

    img {
      margin: 20px 0;
      max-width: 100%;
      width: 100%;
    }

    p {
      font-size: .9rem;
      line-height: 1.7;
    }
  }
}

// Blog Detail
// --

.blog-content {
  article {
    .container {
      margin: 0 auto;
      max-width: 710px;
      width: 100%;
    }

    h3 {
      margin-bottom: 20px;
    }

    h1.blog-title {
      font-size: 1.875rem;
    }

    img {
      max-width: 100%;
      max-height: 500px;
    }

    p {
      margin-top: 20px;
    }

    figure {
      margin-top: 20px;
    }
  }
}

.related-posts {
  &--section-title {
    margin-bottom: 40px;
  }

  .posts-item {
    h3 {
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 20px;
    }
  }
}
