// This is icon cart
.cart-info {
  align-items: center;
  display: flex;
  font-family: $diaBold;
}

.cart-info > .cart-info__label {
  @include font-size(11);
  align-items: center;
  color: $medium-gray;
  display: flex;
  float: left;
  letter-spacing: 1px;
  margin-right: .45rem;
  margin-left: 5px;
}

// Cart Icon
.cart-info > .appicon::before {
  @include font-size(15);
  color: $primary-color;
  margin-right: .1rem;
}

// This is the badge for total number of items in cart
.cart-info--badge {
  @include font-size(12);
  align-items: center;
  background-color: $primary-color;
  border-radius: 50%;
  color: $white;
  display: flex;
  flex-direction: column;
  height: 22px;
  justify-content: center;
  letter-spacing: normal;
  width: 23px;
}

.e-form-detail {
  text-align: center;

  a {
    text-decoration: underline;
  }
}

// thin container wrap
.e-thin-wrap {
  margin: 0 auto;
  max-width: 440px;
}

// padding ecommerce form
.e-padding {
  padding: 0 0 3.125rem;

  @include breakpoint(769px up) {
    padding: 0 0 6.25rem;
  }
}

// Parts Card
.e-card-parts {
  display: inline-block;
  margin-bottom: 2rem;
  width: 100%;
}

.e-card-parts > figure {
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: .375rem;
  outline-color: $mercury-color;
  outline-offset: -1px;
  outline-style: solid;
  outline-width: 1px;
}

.e-card-parts > figure > img {
  opacity: 0;
}

.e-card-parts--label {
  @include font-size(12);
  background-color: $secondary-color;
  color: $white;
  display: table;
  font-family: $diaBold;
  height: 70px;
  text-align: center;
  transition: background-color .25s ease;
  width: 100%;
}

.e-card-parts--label > span {
  display: table-cell;
  vertical-align: middle;
}

.e-card-parts:hover,
.e-card-parts:focus {
  > .e-card-parts--label {
    background-color: darken($secondary-color, 8%);
  }
}

.e-text-center-medium {
  @include breakpoint(medium down) {
    text-align: center;
  }
}
