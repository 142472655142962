[type='text'],
[type='password'],
[type='date'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
textarea {
  height: 3.6em;
  letter-spacing: 1.4px;
  margin: 0 0 1.6rem;
  padding: .688rem 1rem;
  &.error {
    border-color: $input-error-color;
  }
}

[type='search'] {
  margin-bottom: 0;
}

// Input form
.input-form {
  margin: 0 0 1.6rem;

  input,
  textarea {
    margin-bottom: 0;
  }

  &.is-transparent {
    input,
    textarea {
      background: none;
    }
  }

  &.inline {
    display: inline-block;
  }
}

// height: 60px
.input-height-large {
  height: 4.3em;
}

// asterisk require
.req {
  @include font-size(20);
  font-family: $leituraNewsRoman1;
}
