/* purgecss start ignore */
.ham-header {
    height: 21px;

    // 1024px and up
    @include breakpoint(large up) {
        margin-right: 40px;
    }
}

.ham-header__line {
    background-color: #58575a;
    width: 26px;
    height: 2px;
    margin-bottom: 7px;
    border-radius: 1px;
    transition-property: all;
    transition-duration: 200ms;
}

.ham-header__line:last-child {
    margin-bottom: 0;
}

.header-theme--white.headroom--top {
    .ham-header__line {
        background-color: #fff;
    }
}
/* purgecss end ignore */