// Link caret
// ---
.link-caret {
  @include font-size(11);
  color: inherit;
  font-family: $diaBold;
  transition: color .25s ease;

  &.is-large {
    @include font-size(14);
  }
}

.link-caret--before > .caret {
  margin-right: 5px;
}

.link-caret--after > .caret {
  margin-left: 5px;
}
