// Custom style for slider and carousel control arrows
// ---

// Arrow Style 1
// `slick-control-1` add this to parent element slick
.slick-control-1 {
  .slick-arrow {
    backface-visibility: hidden;
    background-color: $porcelain-color;
    border: 1px solid transparent;
    border-radius: 50%;
    height: 45px;
    outline: none;
    overflow-x: hidden;
    position: relative;
    transition-duration: .25s;
    transition-property: background-color, border-color;
    transition-timing-function: ease;
    width: 45px;
    z-index: 1;

    &.slick-next::before,
    &.slick-prev::before {
      content: '';
      left: 50%;
      position: absolute;
      top: 55%;
      transform: scale(1.5) translate(-50%, -50%);
      transition: color .25s ease;
      z-index: 1;
    }

    // Prev
    &.slick-prev {
      &::before {
        @include caret-arrow($dark-gray, left);
      }
    }

    // Next
    &.slick-next {
      &::before {
        @include caret-arrow($dark-gray, right);
        left: 56%;
      }
    }

    // hover
    &:hover::before {
      color: $primary-color;
    }

    &:hover {
      background-color: darken($porcelain-color, 5%);
    }

    &:focus {
      border: 1px solid $light-gray;
    }
  }

  // Control on left and right
  &.control-on-side {
    > .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    > .slick-prev {
      left: 5px;
    }

    > .slick-next {
      right: 5px;
    }
  }
}

// END Arrow Style 1
