






































































































.product-finish-app {
  width: 100%;
  margin: 50px 20px;
  position: relative;
  &--content {
    border: 1px solid #c9c9c9;
  }
  .product-cover-description {
    display: block;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    color: #1b6f9f;
    font-family: Dia Regular,sans-serif;
  }
}
