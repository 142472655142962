// Menu
// ----

// This is the `nav` container
.main-nav {
  display: none;
  width: 100%;
  // 1024px and up
  @include breakpoint(large up) {
    display: block;
  }
}

// This is the `ul` list
.main-nav--menu {
  list-style: none;
  margin: .4rem 0;
}

// This is for small dropdown parent
.main-nav--dropdown-parent-small::before {
  @include visibility(hide);
  background-color: $white;
  bottom: 0;
  content: '';
  height: 5px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.main-nav--dropdown-parent-small:hover {
  background-color: $white;
  box-shadow: 0 .125rem .625rem 0 rgba($black, .15);
  position: relative;
  > a {
    color: $primary-color !important;
  }

  &::before {
    @include visibility(show);
  }
}

// This is the `li` list
.main-nav--menu > .main-nav--item {
  backface-visibility: hidden;
  display: inline-block;

  // is active menu
  &.is-active > .main-nav--parent-anchor {
    color: $primary-color;
  }
}

// This is the parent menu item link
.main-nav--parent-anchor {
  @include font-size(12);
  color: inherit;
  font-family: $diaBold;
  // added `2.92rem` of padding at the bottom to be able to hover the dropdown container
  padding: 0 .35rem 2.92rem;
  position: relative;
  transition: color .25s ease;
  text-transform: uppercase;

  // 1400px and up
  @include breakpoint(1400px up) {
    @include font-size(13);
    // added `2.92rem` of padding at the bottom to be able to hover the dropdown container
    padding: 0 .7rem 2.92rem;
  }
}

// This is the parent menu item link hover state
.main-nav--parent-anchor:hover {
  color: $primary-color;
}

// Dropdown
.main-nav--submenu,
.main-nav--submenu-small {
  @include visibility(hide);
  backface-visibility: hidden;
  background: $white;
  border-bottom: 2px solid $primary-color;
  position: absolute;
}

.main-nav--submenu {
  border-top: 1px solid #e1e1e1;
  box-shadow: 0 .375rem .625rem 0 rgba($black, .15);
  left: 0;
  top: 100%;
  width: 100%;

  // submenu container
  > .main-nav--container {
    padding: 3rem .8rem;

    // 1280px and up
    @include breakpoint(1280px up) {
      padding: 3rem 4.6rem;
    }
  }
}

// Submenu small
.main-nav--submenu-small {
  box-shadow: 0 .125rem .625rem 0 rgba($black, .15);
  min-width: 140px;
  padding: .8rem .7rem;
  text-align: left;
}

.submenu-small-ul > li {
  line-height: 1;
}

.submenu-small-ul > li:not(:last-child) {
  margin-bottom: .5rem;
}

.submenu-small-ul > li > a {
  @include font-size(13);
  color: $jumbo-color;
  font-family: $diaRegular;
}

.submenu-small-ul > li > a:hover {
  color: $primary-color;
}

// Dropdown menu item on hover
.main-nav--dropdown-parent:hover {

  // Menu parent item
  .main-nav--parent-anchor {
    color: $primary-color;
  }

  // Show dropdown menu
  .main-nav--submenu,
  .main-nav--submenu-small {
    @include visibility(show);
    transform: none;
  }
}

// For accessisability, had to separate it from `hover` to prevent IE10 issue
.main-nav--dropdown-parent:focus-within {

  // Menu parent item
  .main-nav--parent-anchor {
    color: $primary-color;
  }

  // Show dropdown menu
  .main-nav--submenu {
    @include visibility(show);
    transform: none;
  }
}

// main list style inside dropdown
.submenu-list {
  list-style: none;
  margin-left: 0;

  li {
    line-height: 1.2;
  }

  li > a {
    color: inherit;
    font-family: $diaBold;
    transition: color .25s ease;
  }

  li > a:hover,
  li > a:focus {
    color: $primary-color;
  }

  li:not(:last-child) {
    margin-bottom: 15px;
  }
}

// White header menu nav item color
.header-theme--white.headroom--top {
  .main-nav--parent-anchor {
    color: $white;
  }

  .main-nav--parent-anchor:hover,
  .main-nav--parent-anchor:focus {
    color: $primary-color;
  }
}

// Submenu title
.main-nav--subtitle {
  @include font-size(13);
  color: $medium-gray;
  font-family: $diaBold;
  margin-bottom: .5rem;
}

.main-nav-sublist {
  list-style: none;
  margin-left: 0;
}

.main-nav-sublist--item {
  > a > span {
    @include font-size(13);
    display: inline-block;
  }

  > a > span:nth-child(1) {
    color: $jumbo-color;
    font-family: $diaRegular;
    min-width: 105px;
  }

  > a > span:nth-child(2) {
    color: $primary-color;
    font-family: $diaBold;
    min-width: 35px;
    text-align: right;
  }

  > a:hover > span:nth-child(1) {
    color: $primary-color;
  }

  // secondary link
  &.secondary {
    margin-top: 1.5rem;

    > a > span {
      color: $secondary-color;
    }

    > a:hover > span {
      color: $secondary-color;
    }
  }
}
