// Range detail page
// ---

.design-option-group {
  padding: 0 .8em;

  // 640px and up
  @include breakpoint (640px up) {
    padding: 1em 2em;
  }
}


// Range Detail Landing Slider
// ---
.range-slider--slider {
  margin: 0 1rem;
}

.range-slider--img {
  height: 460px;
  max-height: 460px;
  min-height: 460px;
}

// Arrows + counter
.range-slider-arrow {
  display: flex;
  position: relative;
}

.range-slider-arrow > .count-block {
  margin: 0 .6em;
}
