// Error
// ---
.center-block--error > h1 {
  // 768px and up
  @include breakpoint(768px up) {
    @include font-size(70);
  }
  color: $shipGray-color;
}

.error-search {
  margin: 0 auto;
  max-width: 410px;

  // 1024px and up
  @include breakpoint(large up) {
    min-height: 400px;
  }
}
