// Figure that has right caret
// Figure image with caret
.fig-caret {
  overflow: hidden;
  position: relative;
}

.fig-caret::before {
  background-color: $secondary-color;
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transition-duration: .3s;
  transition-property: transform, opacity, visibility;
  transition-timing-function: ease;
  width: 20px;
}

.fig-caret::after {
  @include caret-arrow($white, right);
  bottom: 0;
  content: '';
  margin: auto 0;
  position: absolute;
  right: 6px;
  top: 0;
}

// 1024px and up
@include breakpoint(large up) {
  .fig-caret:hover {
    &::before, {
      transform: translateX(100%);
    }

    &::after {
      @include visibility(hide);
    }
  }
}
