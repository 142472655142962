// Column count
// ---

.column-count {
  // Two columns
  &--2 {
    // 640px and up
    @include breakpoint(640px up) {
      column-count: 2;
    }
  }

  // Two columns
  &--3 {
    // 640px and up
    @include breakpoint(640px up) {
      column-count: 3;
    }
  }
}

[class*='column-count--'] {
  column-gap: 40px;
}
