
















































































































.carousel-panel {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 80px 15px;
  position: relative;
}
div.carousel-container {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: auto;
  overflow: hidden;

  .carousel-card {
    border: 3px solid rgba(0, 0, 0, 0);

    display: none;
    &.active {
      display: inline-block;
    }

    width: 150px;
    height: 200px;
    margin: 15px;

    letter-spacing: 1px;
    color: #5c5c5c;
    &--img {
      margin-bottom: 10px;
    }
    &--model-number {
      font-family: "Dia Bold", sans-serif;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
    &--title {
      font-family: 'Dia Regular', sans-serif;
      text-transform: uppercase;
      font-size: 0.75rem;
    }
  }
}

.prev-button, .next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  background-color: #e3e3e3;
  cursor: pointer;
  z-index: 100;
}
.prev-button {
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  .caret {
    border-color: transparent grey transparent transparent;
  }
}
.next-button {
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  .caret {
    border-color: transparent transparent transparent grey;
  }
}
