.cart-block--item {
  background-color: $white;
  padding: 1rem;
}

.cart-block > .cart-block--item:not(:last-child) {
  margin-bottom: 1rem;
}

// Cart Item
.e-cart-item--figure {
  max-width: 120px;
  min-width: 120px;
}

.e-cart-item--name {
  @include font-size(18);
  color: #6c6a71;
  margin: 0;
}

.e-cart-item--itemcode,
.e-cart-item--finish {
  color: $jumbo-color;
  font-family: $diaRegular;
}

.e-cart-item--itemcode {
  @include font-size(16);
  margin-bottom: 1rem;
}

.e-cart-item--finish {
  @include font-size(12);
}

// Remove Item
.close-cart,
.small-square-block {
  @include font-size(12);
  align-items: center;
  background-color: $regent-color;
  color: $white;
  display: flex;
  height: 46px;
  justify-content: center;
  transition: background-color .25s ease;
  width: 46px;
}

.small-square-block {
  @include font-size(16);
  background-color: lighten($regent-color, 22%);
  font-family: $diaBold;
}

.close-cart:hover,
.close-cart:focus {
  background-color: darken($regent-color, 5%);
}
