div.carousel-container {
  div.carousel-img {
    border: 3px solid rgba(0,0,0,0);
    cursor: pointer;
    &.active {
      border: 3px solid #1b6f9f;
    }
    height: 114px;
    margin-top: 20px;
    margin-bottom: 20px;

    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
