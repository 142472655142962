// Custom Checkbox
// ---
.checkbox {
  border: 2px solid $medium-gray;
  clear: both;
  height: 35px;
  margin-bottom: .8rem;
  margin-right: 12px;
  max-height: 35px;
  max-width: 35px;
  position: relative;
  width: 35px;

  .checkbox--input {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .checkbox--div {
    display: block !important;
    height: 100%;
    left: 0;
    margin: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    &::before {
      display: block;
      opacity: 0;
      position: absolute;
    }
  }

  .checkbox--div::before {
    background: $secondary-color;
    content: '';
    height: 100%;
    width: 100%;
  }

  .checkbox--input:checked + .checkbox--div::before {
    opacity: 1;
  }

  // 1024px and up
  @include breakpoint(large up) {
    &:hover > label::before {
      opacity: .15;
    }
  }
}

.checkbox:focus > label::before {
  opacity: 1;
}

// Input form
.input-form > .checkbox {
  float: left;
}

.input-form > .checkbox ~ label {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  height: 2.5em;
  line-height: 1.4;

  > a {
    padding: 0 .3rem;
    text-decoration: underline;
  }
}
