// Icon
// ---

// Search trigger icon
.search-icon {
  color: inherit;
  font-size: 18px;
  max-height: 25px;
  min-width: 20px;
}

// Mobile nav trigger icon
.hamburger-menu-icon {
  margin: .2rem 0;
  min-width: 31px;
}
