// Accordion FAQ
// ---
.accordion--faq {
  .accordion-item {

    .accordion-title {
      @include font-size(14);
      align-items: center;
      background-color: $porcelain-color;
      border: 0;
      color: $medium-gray;
      display: flex;
      font-family: $diaLatoBlack;
      height: 75px;
      letter-spacing: 2px;
      line-height: normal;
      padding-right: 3rem;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color .25s ease;
    }

    .accordion-content {
      @include font-size(14);
      border-color: $porcelain-color;
      border-style: solid;
      border-width: 1px;
    }

    // remove default caret
    .accordion-title::before {
      display: none;
    }

    .accordion-title > .accordion-caret {
      background-color: $jumbo-color;
      border-radius: 50%;
      display: block;
      height: 30px;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      transition-duration: .3s;
      transition-property: background-color, transform;
      transition-timing-function: ease;
      width: 30px;

      &::before {
        @include caret-arrow($white, down);
        backface-visibility: hidden;
        content: '';
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.is-active > .accordion-title {
      background: $secondary-color;
      color: $white;
    }

    &.is-active > .accordion-title > .accordion-caret {
      background-color: $secondaryDark-color;
    }

    &.is-active > .accordion-title > .accordion-caret::before {
      transform: translate(-50%, -50%) rotate(-180deg);
    }

    &.is-active > .accordion-title::before {
      content: '\e908';
    }
  }

  .accordion-item:not(.is-active):not(:last-child) {
    border-bottom: 2px solid $white;
  }

  .accordion-item:last-child {
    > .accordion-content {
      border-bottom: 1px solid $porcelain-color;
    }
  }
}
