// Default Select tag
// ---
select {
  height: 3.6em;
  margin: 0 0 1.6rem;
  padding-left: 1rem;
}


// Custom Select
// ---
.select {
  margin: 0 0 1.6rem;
  overflow: hidden;
  position: relative;
  transition: box-shadow .5s, border-color .25s ease-in-out;

  select {
    appearance: none;
    background: none;
    border: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    transition-duration: .25s;
    transition-property: background-color;
    transition-timing-function: ease;
    white-space: nowrap;

    &:disabled {
      background: transparent;
    }
  }

  input {
    background: none;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    transition-duration: .25s;
    transition-property: background-color;
    transition-timing-function: ease;
    white-space: nowrap;

    @include font-size(14);
    color: $medium-gray;
    background-color: $white;
    border: 1px solid $shipGray-color;
    //background-color: inherit;
    //padding-right: 2.5rem;

    &:disabled {
      background: transparent;
    }
  }

  // Style 1
  // ---
  &--default {
    //background-color: $white;
    //border: 2px solid $shipGray-color;


    select {
      @include font-size(14);
      color: $medium-gray;
      background-color: $white;
      border: 1px solid $shipGray-color;
      //background-color: inherit;
      padding-right: 2.5rem;
    }

    &::after {
      pointer-events: none;
      background-image: url('../../../images/icons-svg/dropdown-caret.svg');
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      height: 15px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
    }

    &.small-icon {
      &::after {
        @include font-size(8);
        height: 1.3em;
      }
    }
  }

  // Style 2
  // ---
  &--round {
    background-color: inherit;
    border-radius: 25px;
    cursor: pointer;
    display: inline-block;

    select {
      color: $white;
      cursor: pointer;
      background-color: inherit;
      height: 3.22em;
      padding-right: 2.5rem;
      text-align: center;
      text-align-last: center;
    }

    // primary round select
    &.primary {
      background-color: $primary-color;

      select:focus {
        background-color: darken($primary-color, 5%);
      }
    }

    // secondary round select
    &.secondary {
      background-color: $secondary-color;

      select:focus {
        background-color: darken($secondary-color, 5%);
      }
    }

    // dark round select
    &.dark {
      background-color: $dark-gray;

      select:focus {
        background-color: darken($dark-gray, 5%);
      }
    }

    &.has-caret::after {
      border-color: $white transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0;
      content: '';
      height: 0;
      position: absolute;
      right: 20px;
      top: 50%;
      pointer-events: none;
      transform: translateY(-50%) scale(1.4);
      width: 0;
    }

    // expanded
    &.expanded {
      width: 100%;
    }
  }
}

// Dropdown Hybrid
// ---
.dropdown-hybrid {
  display: flex;
  margin: 0 0 1.6rem;

  .select {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 .6rem;
    min-width: 180px;
    // padding: .5rem 1rem;
    text-align: center;
  }

  select {
    background-color: inherit;
    display: inline-block;
    height: 100%;
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
  }

  .badge-circle:hover {
    background-color: darken($porcelain-color, 5%);
  }

  .badge-circle:focus {
    border: 1px solid $light-gray;
  }
}
