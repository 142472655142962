// Dealer Locator
// ---

.dealer-errors {
  text-align: left;
  color: red;
}

// Dealer results block
.dealer-block {
  background-color: $porcelain-color;
  margin-bottom: 2rem;
  min-height: 380px;
  padding: 3.5rem 1rem;
  position: relative;

  // 1024px and up
  @include breakpoint(large up) {
    padding: 3.5rem;
  }
}

.dealer-block--tag,
.dealer-block--viewmap,
.dealer-block--distance {
  font-family: $diaBold;
  position: absolute;
}

// Tag
.dealer-block--tag {
  @include font-size(13);
  color: $white;
  left: 0;
  padding: .814rem;
  text-transform: uppercase;
  top: 0;

  // platinum tag
  &.tag-platinum {
    background-color: $platinum-color;
  }

  // gold tag
  &.tag-gold {
    background-color: $gold-color;
  }

  // silver tag
  &.tag-silver {
    background-color: $silver-color;
  }
}

// Heading text title
.dealer-block--text {
  margin-top: .5rem;

  h3 {
    color: $shipGray-color;
  }
}

// Viewmap
.dealer-block--viewmap {
  background-color: $white;
  bottom: 0;
  color: $dark-gray;
  letter-spacing: 3px;
  padding: 1rem;
  right: 0;
  transition: color .25s ease;

  &:hover > .caret {
    @include caret-color($primary-color, right);
  }
}

// distance
.dealer-block--distance {
  @include font-size(20);
  color: rgba($medium-gray, .5);
  padding: .5rem .8rem;
  right: 0;
  top: 0;
}

// contact badge

.dealer-contact-badge {
  display: table;
  list-style: none;
  margin: 0 auto;
}

.contact-badge--linkbadge {
  @include font-size(18);
  color: $jumbo-color !important;
  display: block;
  font-family: $diaRegular;
  margin-bottom: .3rem;
  transition: color .25s ease;

  > .badge-circle {
    @include font-size(20);
    color: $white;
    display: inline-block;
    font-family: $diaBold;
    margin-right: .6rem;
    padding: .5rem;
    text-align: center;
  }

  // 1024px and up
  @include breakpoint(large up) {
    @include font-size(20);
    margin-bottom: .6rem;
  }
}

.contact-badge--item:nth-child(1):hover > .contact-badge--linkbadge > .badge-circle {
  background-color: darken($primary-color, 10%);
}

.contact-badge--item:nth-child(2):hover > .contact-badge--linkbadge > .badge-circle {
  background-color: darken($secondary-color, 10%);
}

.contact-badge--item:nth-child(3):hover > .contact-badge--linkbadge > .badge-circle {
  background-color: darken($shipGray-color, 10%);
}
