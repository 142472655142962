// Custom Dropdown
// ---
.dropjs {
  background: $white;
  cursor: pointer;
  display: inline-block;
  height: 3.14em; // minus borders
  margin: 0 0 1.6rem;
  position: relative;
  width: 100%;

  &::after {
    background-image: url('../../../images/icons-svg/dropdown-caret.svg');
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    height: 15px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
  }

  // .dropjs--input {
  //   @include no-select;
  //   border: 2px solid $shipGray-color;
  //   color: $black;
  //   cursor: pointer;
  //   display: inline-block;
  //   margin: 0;
  //   outline: none;
  //   overflow: hidden;
  //   text-align: left;
  //   text-overflow: ellipsis;
  //   transition: box-shadow .5s, border-color .25s ease-in-out;
  //   white-space: nowrap;
  //   width: 100%;
  //
  //   &:focus {
  //     cursor: none;
  //   }
  // }

  // Hidden `Select` input tag
  .dropjs--select {
    display: none;
  }

  // Dropdown Label
  .dropjs--label {
    @include no-select;
    align-items: center;
    border: 2px solid $shipGray-color;
    display: flex;
    font-family: $diaBold;
    height: 100%;
    padding: .688rem 1rem;
  }

  .dropjs--droplist {
    background-color: $white;
    border-bottom: 2px solid $dark-gray;
    border-left: 2px solid $dark-gray;
    border-right: 2px solid $dark-gray;
    display: none;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: auto;
    z-index: 10;
  }

  .dropjs--list {
    backface-visibility: hidden;
    margin: 0;
    max-height: 228px;
    min-width: 205px;
    overflow-y: auto;
    width: 100%;
    will-change: scroll-position;
  }

  .dropjs--searchinput {
    border-width: 0 0 1px;
    margin-bottom: 0;

    &::placeholder {
      opacity: .5;
    }
  }

  .dropjs--list > .dropjs--item {
    @include font-size(15);
    color: $dark-gray;
    cursor: pointer;
    display: inline-block;
    font-family: $diaBold;
    line-height: normal;
    padding: .65rem 1rem;
    position: relative;
    text-align: left;
    transition: color .25s ease;
    width: 100%;
    word-break: break-all;

    li:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }

    &.is-selected:not(.no-results),
    &:hover:not(.no-results),
    &:focus:not(.no-results) {
      background-color: $light-gray;
      color: $black;
    }
  }

  // Is open
  &.is-open {
    &::after {
      top: 30%;
      transform: rotate(-180deg);
    }

    // .dropjs--input {
    //   border-color: $dark-gray;
    // }

    .dropjs--droplist {
      display: block;
    }
  }

  // Dropdown with search
  &--search {
    // .dropjs--input {
    //   margin-bottom: 0;
    // }
  }
}

.dropjs.disabled,
.dropjs[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}
