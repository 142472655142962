// Base Helpers
// ---
p,span {
  // tiny
  &.is-tiny {
    @include font-size(11);
  }

  // small
  &.is-small {
    @include font-size(14);
  }

  // medium
  &.is-medium {
    @include font-size(15);
  }

  // large
  &.is-large {
    @include font-size(18);
  }

  // x-large
  &.is-xlarge {
    @include font-size(22);
  }
}

.has-small-p {
  p {
    @include font-size(14);
  }
}

.has-medium-p {
  p {
    @include font-size(15);
  }
}

.has-large-p {
  p {
    @include font-size(18);
  }
}

.has-xlarge-p {
  p {
    @include font-size(22);
  }
}

// Letter spacing
// ---
.letter-spacing-0 {
  letter-spacing: 0;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

// Line Height
// ---
.line-height-normal {
  line-height: normal;
}

.line-height-1 {
  line-height: 1.2;
}

.line-height-2 {
  line-height: 1.5;
}

.line-height-3 {
  line-height: 1.8;
}

// fonts
// ---

// Regular fonts
.font-regular-1,
.has-font-regular-1 > p {
  font-family: $watermarkHandRegular;
}

.font-regular-2,
.has-font-regular-2 > p {
  font-family: $diaRegular;
}

.font-regular-3,
.has-font-regular-3 > p {
  font-family: $leituraDisplayRoman;
}

.font-regular-4,
.has-font-regular-4 > p {
  font-family: $leituraNewsRoman1;
}

.font-regular-5,
.has-font-regular-5 > p {
  font-family: $waterletHandOutlineReg;
}

.font-regular-6,
.has-font-regular-6 > p {
  font-family: $waterletHandFillReg;
}

// Bold fonts
.font-bold-1,
.has-font-bold-1 > p {
  font-family: $diaBold;
}

.font-bold-2,
.has-font-bold-2 > p {
  font-family: $diaLatoBlack;
}

.font-bold-3,
.has-font-bold-3 > p {
  font-family: $waterletHandOutlineBold;
}

// Italic fonts
.font-italic-1,
.has-font-italic-1>p {
  font-family: $diaRegular;
  font-style: italic;
}

.font-italic-2,
.has-font-italic-2>p {
  font-family: $leituraDisplayRoman;
  font-style: italic;
}

.font-italic-3,
.has-font-italic-3>p {
  font-family: $leituraNewsRoman1;
  font-style: italic;
}

// Color helpers
// ---

// secondary text color
.has-text-primary {
  color: $primary-color;
}

// primary deep text color
.has-text-primary-darker {
  color: $aquaDeep-color;
}

// secondary text color
.has-text-secondary {
  color: $secondary-color;
}

// secondary text color darker
.has-text-secondary-darker {
  color: $secondaryDark-color;
}

// white text color
.has-text-white {
  color: $white;
}

// Dark text color
.has-text-dark {
  color: $dark-gray;
}

.has-text-darker {
  color: $shipGray-color;
}

.has-text-medium-light {
  color: rgba($medium-gray, .8);
}

.has-text-light {
  color: rgba($medium-gray, .3);
}

// Platinum
.has-text-platinum {
  color: $platinum-color;
}

// gold
.has-text-gold {
  color: $gold-color;
}

// silver
.has-text-silver {
  color: $silver-color;
}

// Underline
.has-underline {
  text-decoration: underline;
}

// blockquote
// ---
// blockquote {
//   line-height: normal;
//   padding-top: 15px;
//   position: relative;
//
//   &::before {
//     color: $primary-color;
//     content: '\201C';
//     font-family: $geogrotesqueBold;
//     left: 0;
//     position: absolute;
//     top: 0;
//   }
//
//   &.is-big {
//     font-family: $geogrotesqueUltraLight;
//     font-size: 45px;
//     padding-left: 40px;
//
//     &::before {
//       font-size: 58px;
//     }
//   }
// }
