.loading-full {
  @include visibility(hide);
  align-items: center;
  background: rgba($white, .9);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition-duration: .15s;
  transition-property: opacity, visibility, transform;
  transition-timing-function: ease;
  width: 100%;
  z-index: 5000;

  svg > g > g > .gear {
    backface-visibility: hidden;
  }
}

// This is used in `Dealer Locator`
.loading-full--one {
  // Loading Visible
  &.is-loading {
    @include visibility(show);
  }
}

.loading-full--fit-container {
  @extend .loading-full;
 position: absolute;

  &.is-loading {
    @include visibility(show);
  }
}