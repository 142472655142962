// General
// ---

// Page Title Banner Image
.page-title-banner {
  background: $black;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 30px;
  overflow: hidden;
  position: relative;

  * {
    color: $white;
  }

  .aligned-content {
    @include xy-grid-container;
    align-items: center;
    display: table;
    height: 100%;
    min-height: 330px;
    padding: 50px 0;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .aligned-content > .middle-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

// overlay
.page-title-banner::before {
  @include overlay-transparent($black, .54);
}


// Main page content padding
// This is use to have a gap between the header and the content
.page-content-margin {
  // Use 6.15em 0; when not displaying warning banner
  //margin: 11.15em 0;
  margin: 6.15em 0;;
  &.no-bottom {
    margin-bottom: 0;
  }
}

// grid container
.grid-container {
  // Grid container width 1 `.grid-container--width1`
  &--width1 {
    max-width: 700px;
  }

  &--width2 {
    max-width: 1100px;
  }

  &--width3 {
    max-width: 900px;
  }
}

// Section margin
.section-margin {
  @include breakpoint(large up) {
    margin: 100px 0;
  }
  @include breakpoint(medium down) {
    margin: 50px 0;
  }
  @include breakpoint(767px down) {
    margin: 30px 0;
  }
}

// Center Block
.center-block {
  margin: 0 auto;
  max-width: 600px;
  padding: 0 15px;
  text-align: center;
}

// Keep Scroll
// ---
.keep-scroll {
  font-family: $diaBold;
  padding: 2rem 1rem;
  position: fixed;
  right: -1.7em;
  top: 50%;
  transition-duration: .4s;
  transition-property: transform, opacity, visibility;
  transition-timing-function: ease-out;
  z-index: 2;

  // 1023px and below
  @include breakpoint(medium down) {
    display: none;
  }

  &.hide {
    transform: translateX(100%);
    transition-timing-function: ease-in;
  }
}

.keep-scroll--text {
  @include font-size(11);
  color: #323233;
  display: inline-block;
  letter-spacing: 1.4px;
  text-shadow: 0 0 15px rgba($white, 1);
  transform: rotate(90deg) translateX(-5em);
}

.keep-scroll > .keep-scroll--badge {
  background-color: #323233;
  cursor: default;
  display: flex;
  margin: 0 auto;
  max-height: 30px;
  max-width: 30px;
}

.keep-scroll > .keep-scroll--badge > .caret {
  @include caret-color($white, down);
}

// Video banner offset
.vid-banner-offset > .vid-banner-offset--cell-video {
  // 1024px and up
  @include breakpoint(large up) {
    order: 2;
  }
}

.vid-banner-offset--cell-text {
  position: relative; // allows easter eggs to be placed inside
}

.vid-banner-offset--video {
  border: 1.25rem solid $white;
  margin-top: -15em;
  position: relative;
  z-index: 4;

  // 1200px and up
  @include breakpoint(large up) {
    right: 15px;
  }

  // 1023px and up
  @include breakpoint(medium down) {
    margin-top: -8em;
  }

  // 767px and down
  @include breakpoint(767px down) {
    margin-top: -5em;
  }

  // 639px and down
  @include breakpoint(small down) {
    border-width: .6rem;
  }
}

.vid-banner-offset--heading {
  @include font-size(40);
}


// Easter Eggs
.easter-egg {
  font-size: 45px;
  &.abs {
    position: absolute;
    &-right {
      position: absolute;
      right: 0;
    }
    &-left {
      position: absolute;
      left: 0;
    }
  }
}