




























































































































































.display-none {
    display: none;
}
.show-on-top {
    z-index: 99;
}
.finishes-slider {
    position: relative;
    width: 100%;
    height: 650px;
    z-index: 3;
    overflow: hidden;
    .slider-slide {
        position: absolute;
        width: 100%;
        height: 100%;
        .slide-content {
            position: relative;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.5s linear 0s;
            img {
                max-width: 100%;
                max-height: 100%;
            }
            .slide-background {
                position: relative;
                width: 100%;
                height: 600px;
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: contain;
                /*background-color: #e6e6e6;*/
                background-color: transparent;
                &--blur {
                    position: absolute;
                    width: 110%;
                    height: 110%;
                    top: -5%;
                    left: -5%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-color: #e6e6e6;
                    filter: blur(12px);
                    -webkit-filter: blur(12px);
                    &-container {
                        position: absolute;
                        width: 100%;
                        height: 600px;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        overflow: hidden;
                    }
                }
            }
            .slide-caption {
                margin-top: 10px;
                p {
                    a {
                        font-family: "Leitura Display Roman";
                        color: #1b6f9f;
                    }
                }
            }
            .slide-link {
                padding: 25px 0 0 25px;
                position: absolute;
                right: 0;
                bottom: 0;
                a.button {
                    padding: 25px 80px;
                    margin: 0;
                    letter-spacing: 4px;
                }
            }
        }
    }
}
.arrow {
  margin-left: 15px;
  border: 4px solid transparent;
  border-right-color: white;
  border-bottom-color: white;
  display: inline-block;
  padding: 0;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.prev-button, .next-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 300px;
    background-color: #eeeeee;
    z-index: 100;
    .caret {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.prev-button {
    left: 50px;
    .caret {
        border-color: transparent grey transparent transparent;
    }
}
.next-button {
    right: 50px;
    .caret {
        border-color: transparent transparent transparent grey;
    }
}

.image-list {
}
.image-list img {
}

.slider-dots {
    padding: 15px;
    display: block;
    position: absolute;
    [data-goto-slide] {
        cursor: pointer;
        height: 25px;
        width: 25px;
        position: relative;
        display: inline-block;
        @media (max-width: 860px) {
            display: block;
        }
        .dot {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 5px;
            width: 5px;
            background-color: #fff;
            border-radius: 50%;
            display: inline-block;
        }
    }
    [data-goto-slide].active {
        .dot {
            height: 12px;
            width: 12px;
            background-color: transparent;
            border-radius: 50%;
            display: inline-block;
            border: 2px solid #fff;
        }
    }
}
