









































.price-group-tabs {
  display: block;
  margin: auto;
  width: calc(100% - 320px);
  padding: 10px;

  @media screen and (max-width: 800px){
    width: 100%;
  }
  &--container {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
  &--tab {
    font-family: "Dia Bold", sans-serif;
    font-size: 0.85rem;
    text-transform: uppercase;
    padding: 4px;
    cursor: pointer;

    color: #aaaaaa;
    &.active {
      color: #1b6f9f;
    }
  }
}
