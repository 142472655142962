// Footer Section
// ---
.site-footer {
  background-color: $dark-gray;
  padding: 50px 0;
  position: relative;
  z-index: 3;

  @include breakpoint(large up) {
    padding: 100px 0 50px;
  }
}

.footer-rights {
  color: $white;
  font-family: $diaRegular;
  text-transform: uppercase;
}

.footer-rights > p {
  @include font-size(14);
  line-height: 1.2;
}

// Scroll to top
.scroll-to {
  background-color: $white;
  border-radius: 50%;
  bottom: 20px;
  display: inline-block;
  height: 58px;
  position: absolute;
  right: 20px;
  transition-duration: .25s;
  transition-property: transform, opacity, visibility, background-color;
  transition-timing-function: ease;
  width: 58px;

  @include breakpoint(768px up) {
    bottom: 100px;
    right: 100px;
  }
}

.scroll-to > .appicon {
  @include font-size(32);
  color: $black;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@include breakpoint(large up) {
  .scroll-to:hover {
    background-color: $primary-color;

    .appicon {
      color: $white;
    }
  }
}

.newsletter-footer {
  margin-bottom: 3.3rem;
}

.social-footer,
.footer-logo {
  margin-bottom: 5rem;
}
