.outer-site-wrap {

  // Active Search
  &.active-search {
    .header-search {
      opacity: 1;
      transform: translate3d(.9375rem, 0, 0);
      transition-duration: .4s;
      transition-property: opacity, transform;
      transition-timing-function: cubic-bezier(.645, .045, .355, 1);
      z-index: auto;

      // 1280px and up
      @include breakpoint(1280px up) {
        transform: translate3d(4.5rem, 0, 0);
      }
    }

    .sh-section:not(.sh-section--search) {
      @include visibility(hide);
    }

    .search-icon > .search-icon__magnify {
        display: none;
    }

    .search-icon > .search-icon__close {
        display: inline;
    }

    // .sh-section--search > button > .appicon-search::before {
    //   content: '\F103';
    // }
  }

  // Active Mobile menu
  &.active-hamburger {
    // Hamburger
    .hamburger-menu-icon {
      background-color: transparent;
    }

    .hamburger-menu-icon::before,
    .hamburger-menu-icon::after {
      background-color: $white;
    }

    .hamburger-menu-icon::before {
      transform: rotate(45deg);
    }

    .hamburger-menu-icon::after {
      transform: rotate(-45deg);
    }

    // brand
    .brand > .brand--svg > .watermark-svg-logo {
      fill: $white;
    }

    .brand > .brand--slogan {
      color: $white;
    }

    // 1023px and below
    @include breakpoint(medium down) {
      .site-header {
        background: transparent;
        transform: none;

        .search-icon {
          @include visibility(hide);
        }
      }

      .site-header:not(.header-theme--white) {
        transition-duration: 0s;
      }

      .mobile-nav {
        @include visibility(show);
      }
    }
  }
}
