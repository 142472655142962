.e-detail-block {
  background-color: $porcelain-color;
  padding: 1rem;
}

.e-detail-block--figure {
  text-align: center;
  background-color: $white;
  margin-bottom: 1rem;
  padding: 1rem;

  > img {
    max-height: 350px;
  }
}

.e-detail-block--title,
.e-detail-block--copy {
  margin: 0 0 .3rem;
}

.e-detail-block > .e-detail-block--title {
  @include font-size(32);
  color: $secondary-color;
  font-family: $diaBold;
}

.e-detail-block > .e-detail-block--copy {
  @include font-size(12);
  font-family: $diaRegular;
}
